import React from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { Button, Modal, Form } from "react-bootstrap";
import { useEffect } from "react";
import "./ThreadDetailView.css";
import {
  FaClock,
  FaLastfm,
  FaPen,
  FaQuestion,
  FaThumbsDown,
  FaThumbsUp,
  FaUser,
} from "react-icons/fa";
import { connect } from "react-redux";
import { load_user, checkAuthenticated } from "../actions/auth";
import { useState } from "react";
import ProfileCard from "../components/ProfileCard";
import { combineMessages } from "../util/util";

const ThreadDetailView = ({
  load_user,
  checkAuthenticated,
  user,
  isAuthenticated,
  access,
}) => {
  const { id: thread_id } = useParams();
  //   console.log(thread_id);
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [thread, setThread] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [postContent, setPostContent] = useState("");
  const [viewableComments, setViewableComments] = useState("");
  const [threadLikes, setThreadLikes] = useState(0);

  const [threadDislikes, setThreadDislikes] = useState(0);
  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${access}`,
    },
  };

  const handleErrorResDisplay = (err, defaultMsg) => {
    const res = err.response;

    var message = defaultMsg;
    if (Object.keys(res.data).length !== 0) {
      message = combineMessages(res);
      if (
        res.data.hasOwnProperty("code") &&
        res.data.code === "token_not_valid"
      ) {
        checkAuthenticated();
        message = "Your login has expired. Please try again.";
      }
    }

    setShowNotification(true);
    setNotificationMessage(message);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const createComment = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/forum/${thread_id}/create_post/`;

    try {
      const response = await axios.post(
        url,
        {
          content: postContent,
          id: user.user.id, //this gonna be User class not UserAccount
        },
        config
      );

      if (response.status === 201) {
        alert("submitted");
      }
    } catch (err) {
      console.log(err);
      handleErrorResDisplay(err);
    }
  };
  const submitNewComment = (event) => {
    event.preventDefault();
    createComment();
    // console.log(postContent);
    // Close the modal
    handleClose();
  };

  useEffect(() => {
    const fetchPostsFromThread = async () => {
      const userIdParam = user && user.id ? `?user_id=${user.id}` : "";
      const url = `${process.env.REACT_APP_API_URL}/api/forum/${thread_id}/get_posts${userIdParam}`;
      // console.log(url);
      try {
        const response = await axios.get(url);
        // console.log(response);
        setViewableComments(response.data);
      } catch (err) {
        console.log(err);
      }
    };

    const fetchThread = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/forum/${thread_id}`;

      try {
        const response = await axios.get(url);
        // console.log(response);
        setThread(response.data);
        setThreadLikes(response.data.likes);
        setThreadDislikes(response.data.dislikes);
      } catch (err) {
        console.log(err);
      }
    };
    fetchThread();

    fetchPostsFromThread();
  }, [user]);
  // console.log(viewableComments);

  // console.log(user);
  const handleUpvote = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/forum/${thread.id}/upvote/`;
    // console.log(url);

    try {
      const response = await axios.post(url, { user: user }, config);
      // console.log(response);
      if (response.data.status === "liked") {
        //newly created or from neutral
        setThreadLikes(threadLikes + 1);
      } else if (response.data.status === "neutral") {
        //unliked and turned neutral
        setThreadLikes(threadLikes - 1);
      } else {
        //liked+ means unlike before now likes it
        setThreadDislikes(threadDislikes - 1);
        setThreadLikes(threadLikes + 1);
      }

      // alert(response.data.status);
    } catch (err) {
      console.log(err);
    }
    // console.log("Upvote clicked");
  };

  const handleDownvote = async () => {
    const url = `${process.env.REACT_APP_API_URL}/api/forum/${thread.id}/downvote/`;
    // console.log(url);

    try {
      const response = await axios.post(url, { user: user }, config);
      // console.log(response);

      if (response.data.status === "disliked") {
        //was neutral and wants to dislike it or newly created
        setThreadDislikes(threadDislikes + 1);
      } else if (response.data.status === "neutral") {
        //wants to be neutral, disliked it before
        setThreadDislikes(threadDislikes - 1);
      } else {
        //disliked+ means likes before and now wants to dislike
        setThreadDislikes(threadDislikes + 1);
        setThreadLikes(threadLikes - 1);
      }

      // alert(response.data.status);
    } catch (err) {
      console.log(err);
    }
  };
  //   console.log(user.user.id);
  // not passing content in from link bc users mgiht go to url directly and props wont be passed
  return (
    <div
      style={{
        minHeight: "100vh",
        margin: "10px 30px  0px 30px",
        paddingTop: "20px",
        marginRight: "200px",
        marginLeft: "200px",
        paddingRight: "15px",
        paddingLeft: "15px",

        backgroundColor: "white",
      }}
    >
      <div
        className="thread-discussion"
        style={{
          color: "black",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          // border: "0.5px gray solid",
        }}
      >
        <div
          style={{
            display: "flex",
            width: "100%",
            // border: "0.5px gray solid",
          }}
        >
          {/* Score Section */}
          <div
            style={{
              flex: 0.5, // 1 part of the 1:3 ratio
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              textAlign: "center",
              backgroundColor: "#f5f5f7",
            }}
          >
            <div style={{ fontSize: "22px" }}>Score </div>
            <div
              style={{
                fontSize: "22px",
                alignContent: "center",
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              {threadLikes - threadDislikes}
              <span
                style={{
                  fontSize: " .75rem",
                  letterSpacing: "0",
                  lineHeight: "1.4",
                  background: "#ccc",
                  borderRadius: "8px",
                  color: "#fff",
                  display: "inline-block",
                  height: "16px",
                  lineHeight: "16px",
                  margin: "0 0.5em",
                  textAlign: "center",
                  verticalAlign: "middle",
                  width: "16px",
                }}
                title="Net Thread Score"
              >
                ?
              </span>
            </div>
            <div style={{ fontWeight: "bold", fontSize: "14px" }}>
              {threadLikes}
              <button
                onClick={handleUpvote}
                style={{
                  color: "black",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FaThumbsUp />
              </button>
              {threadDislikes}
              <button
                onClick={handleDownvote}
                style={{
                  color: "black",
                  border: "none",
                  background: "none",
                  cursor: "pointer",
                }}
              >
                <FaThumbsDown />
              </button>
            </div>
          </div>

          {/* Title Section */}
          <div
            style={{
              flex: 3, // 3 parts of the 1:3 ratio
              display: "flex",
              flexDirection: "column",
              padding: "10px",
            }}
          >
            <h1>{thread.title}</h1>
            <div style={{ display: "flex" }}>
              <p style={{ color: "gray", marginRight: "20px" }}>
                {" "}
                {/* Add marginRight to the first and second <p> tags */}
                <FaUser /> {thread.creator_username}
              </p>
              <p style={{ color: "gray", marginRight: "20px" }}>
                {" "}
                {/* This adds space between the second and third <p> tags */}
                <FaClock />{" "}
                {moment(thread.created_at).format("MMM Do, YYYY hh:mm a")}
              </p>
              <p style={{ color: "gray" }}>
                {" "}
                {/* No need to add margin to the last element unless you want space after it */}
                <FaPen />{" "}
                {moment(thread.last_updated).format("MMM Do, YYYY hh:mm a")}
              </p>
            </div>
          </div>
        </div>

        {/* <hr style={{ color: "black", width: "100%" }} /> */}
      </div>

      {isAuthenticated && (
        <Button
          variant="primary"
          style={{ marginTop: "10px" }}
          onClick={handleShow}
        >
          Add Comment
        </Button>
      )}
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton style={{ backgroundColor: "#E0B0FF" }}>
          <Modal.Title>Quick reply</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={submitNewComment}>
            <Form.Group controlId="postContent">
              <Form.Label style={{ color: "black" }}>Comment</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                value={postContent}
                onChange={(e) => setPostContent(e.target.value)}
                required
              />
            </Form.Group>
            <Button
              variant="primary"
              type="submit"
              style={{ marginTop: "10px" }}
            >
              Post
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <div
        style={{
          backgroundColor: "#E0B0FF",
          paddingLeft: "2px",
          alignItems: "left",
          marginTop: "20px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
        }}
      >
        <p style={{ marginBottom: "0px", color: "white", marginLeft: "2px" }}>
          {moment(thread.created_at).format("MMM Do, YYYY hh:mm a")}
        </p>
      </div>
      {/*this is for content of intiial pst */}
      <div
        className="threads"
        style={{
          color: "white",
          display: "flex",
          flexDirection: "column",
          backgroundColor: "grey",
          alignItems: "center",
          // border: "0.5px gray solid",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
          width: "100%", // Adjust this as needed
        }}
      >
        {/* User and Thread Content Section */}

        <div style={{ display: "flex", width: "100%" }}>
          {/* User Details Section */}

          <div
            style={{
              display: "flex",
              position: "relative",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-evenly",
              flex: 0.5, // Adjust flex value as needed to match the size of the Score section
              backgroundColor: "#f0f0f0", // Just to visually differentiate sections, adjust as needed
              paddingTop: "10px",
            }}
          >
            <ProfileCard
              // style={{ marginBottom: "0px" }}
              username={thread.creator_username}
              imageUrl={thread.creator_profile_picture}
              usernameColor={"blue"}
            />

            <p
              className="created"
              style={{
                backgroundColor: "white",
                color: "black",
                background: "#fff",
                padding: " 3px 6px",
                marginBottom: "0px",
                border: " 1px solid #ebebeb",
                borderRadius: " 3px",
                // marginTop: "0px",
              }}
            >
              Joined: {moment(thread.creator_created_on).format("MMM YYYY")}
            </p>
            <p
              className="created"
              style={{
                backgroundColor: "white",
                color: "black",
                background: "#fff",
                padding: " 3px 6px",
                border: " 1px solid #ebebeb",
                borderRadius: " 3px",
              }}
            >
              Messages: {thread.creator_post_count}
            </p>

            <div
              style={{
                position: "absolute",
                right: "0px", // Position the arrow towards the right, inside the div
                top: "50%", // Vertically center the arrow
                width: 0,
                height: 0,
                borderTop: "10px solid transparent", // Upper part of the arrow
                borderBottom: "10px solid transparent", // Lower part of the arrow
                borderRight: "10px solid white", // Arrow color matching the div's background, facing left
                transform: "translateY(-50%)", // Ensure the arrow is centered vertically
              }}
            ></div>
          </div>

          {/* Thread Content Section */}
          <div
            style={{
              flex: 3, // Adjust according to your desired ratio with the User Details section
              display: "flex",
              flexDirection: "column",
              color: "black",
              padding: "10px", // Add some padding for content
              backgroundColor: "#ffffff", // Just to visually differentiate sections, adjust as needed
            }}
          >
            <p>{thread.content}</p>
          </div>
        </div>

        {viewableComments &&
          viewableComments.length > 0 &&
          viewableComments.map((comment) => (
            <div style={{ width: "100%" }}>
              <div
                style={{
                  backgroundColor: "#E0B0FF",
                  paddingLeft: "2px",
                }}
              >
                <p style={{ marginBottom: "0px", marginLeft: "2px" }}>
                  {moment(comment.created_at).format("MMM Do, YYYY hh:mm a")}
                </p>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    flex: 0.5, // Adjust flex value as needed to match the size of the Score section
                    backgroundColor: "#f0f0f0", // Just to visually differentiate sections, adjust as needed
                  }}
                >
                  <ProfileCard
                    imageUrl={comment.author_picture}
                    username={comment.author_username}
                    usernameColor={
                      thread.creator_username === comment.author_username
                        ? "blue"
                        : "gray"
                    }
                  />
                  <p
                    className="created"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      background: "#fff",
                      padding: " 3px 6px",
                      marginBottom: "0px",
                      border: " 1px solid #ebebeb",
                      borderRadius: " 3px",
                      // marginTop: "0px",
                    }}
                  >
                    Joined:{" "}
                    {moment(comment.author_created_on).format("MMM YYYY")}
                  </p>
                  <p
                    className="created"
                    style={{
                      backgroundColor: "white",
                      color: "black",
                      background: "#fff",
                      padding: " 3px 6px",
                      border: " 1px solid #ebebeb",
                      borderRadius: " 3px",
                    }}
                  >
                    Messages: {comment.author_post_count}
                  </p>
                  <div
                    style={{
                      position: "absolute",
                      right: "0px", // Position the arrow towards the right, inside the div
                      top: "50%", // Vertically center the arrow
                      width: 0,
                      height: 0,
                      borderTop: "10px solid transparent", // Upper part of the arrow
                      borderBottom: "10px solid transparent", // Lower part of the arrow
                      borderRight: "10px solid white", // Arrow color matching the div's background, facing left
                      transform: "translateY(-50%)", // Ensure the arrow is centered vertically
                    }}
                  ></div>
                </div>

                {/* Thread Content Section */}
                <div
                  style={{
                    flex: 3, // Adjust according to your desired ratio with the User Details section
                    display: "flex",
                    flexDirection: "column",
                    color: "black",
                    padding: "10px", // Add some padding for content
                    backgroundColor: "#ffffff", // Just to visually differentiate sections, adjust as needed
                  }}
                >
                  <p>{comment.content}</p>
                </div>
              </div>
            </div>
          ))}
      </div>
      {showNotification && (
        <div className="notification">{notificationMessage}</div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  access: state.auth.access,
});

export default connect(mapStateToProps, { load_user, checkAuthenticated })(
  ThreadDetailView
);
