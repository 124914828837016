import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./GameCard.css";

function GameCard({ id, title, imageUrl, slug, titleColor }) {
  // Replace 't_thumb' with 't_cover_big_2x' in the URL
  imageUrl = imageUrl.replace("t_thumb", "t_1080p_2x");
  // console.log("im here", id, title, imageUrl, slug);

  return (
    <Link
      to={`/game/${id}/${slug}`}
      style={{ textDecoration: "none", display: "block" }}
    >
      {" "}
      <div className="game-card">
        <img src={imageUrl} alt={`${title} cover`} />
        <div className="game-title" style={{color:titleColor}}>{title}</div>
      </div>
    </Link>
  );
}

export default GameCard;
