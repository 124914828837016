import axios from 'axios';
import {
    LOGIN_SUCCESS,
    LOGIN_SUCCESS_KEEP_SIGNED_IN,
    REFRESH_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    LOGOUT,
} from '../actions/types';
import { combineMessages } from '../util/util';

// FUNCTIONS THAT AFFECT REDUX STATE

export const checkAuthenticated = () => async dispatch => {
    var accessTokenValid = false;
    var refreshTokenValid = false;

     if (localStorage.getItem('access')) {
        const config  = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({ token: localStorage.getItem('access') });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config)
            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS,
                });
                accessTokenValid = true;
            }
        } catch (err) {
            console.log("Not authenticated");
        }
     }
     
     if (localStorage.getItem('refresh') && !accessTokenValid) {
        const config  = {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        };

        const body = JSON.stringify({ token: localStorage.getItem('refresh') });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify/`, body, config)
            if (res.data.code !== 'token_not_valid') {
                dispatch({
                    type: AUTHENTICATED_SUCCESS,
                });
                refreshTokenValid = true;
                
                const refresh_body = JSON.stringify({ refresh: localStorage.getItem('refresh') });
                const refresh_res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, refresh_body, config);
                
                dispatch({
                    type: REFRESH_SUCCESS,
                    payload: refresh_res.data,
                });
            }
        } catch (err) {
            console.log("Not authenticated");
        }
     }

     if (!accessTokenValid && !refreshTokenValid) {
        dispatch( {
            type: AUTHENTICATED_FAIL,
        });
     } else {
        dispatch(load_user());
     }
};

export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);
            dispatch({
                type: USER_LOADED_SUCCESS,
                payload: res.data,
            });
        } catch (err) {
            dispatch({
                type: USER_LOADED_FAIL,
            });
        }
    }
};

export const login = (username, password, keepSignedInChecked) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ username, password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config);
        if (keepSignedInChecked) {   
            dispatch({
                type: LOGIN_SUCCESS_KEEP_SIGNED_IN,
                payload: res.data,
            });
        } else {
            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });
        }

        dispatch(load_user());
    } catch (err) {
        const res = err.response;
        var message = "Something went wrong with your login.";
        if (Object.keys(res.data).length !== 0) {
            message = combineMessages(res);
        }
        dispatch({
            type: LOGIN_FAIL,
            payload: { message: message },
        });
    }
};

export const signup = (username, email, password, re_password) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ username, email, password, re_password });

    try {
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/`, body, config);
        dispatch({
            type: SIGNUP_SUCCESS,
            payload: res.data,
        });
        
        const body_g = JSON.stringify({ useraccount: res.data.id });
        const res_g = await axios.post(`${process.env.REACT_APP_API_URL}/api/game_users/add_user/`, body_g, config);
    } catch (err) {
        const res = err.response;       
        var message = "Something went wrong with your signup.";
        if (Object.keys(res.data).length !== 0) {       
            message = combineMessages(res);
        } 
        dispatch({
            type: SIGNUP_FAIL,
            payload: { message: message },
        });
    }
};

export const verify = (uid, token) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'application/json'
        }
    };

    const body = JSON.stringify({ uid, token });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/activation/`, body, config);
        dispatch({
            type: ACTIVATION_SUCCESS,
        });
    } catch (err) {
        const res = err.response;       
        var message = "Something went wrong with your account activation.";
        if (Object.keys(res.data).length !== 0) {       
            message = combineMessages(res);
        } 
        dispatch({
            type: ACTIVATION_FAIL,
            payload: { message: message },
        });
    }
}


// FUNCTIONS THAT DO NOT AFFECT REDUX STATE

// RESET PASSWORD FUNCTIONS
export const reset_password = async (email) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };

    const body = JSON.stringify({ email });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password/`, body, config);
        return { success: true };
    } catch (err) {
        const res = err.response;
        var message = "Something went wrong with sending your reset link."
        if (Object.keys(res.data).length !== 0) {
            message = combineMessages(res);
        }
        return { success: false, message: message };
    }
}

export const reset_password_confirm = async (uid, token, new_password, re_new_password) => {
    const config = {
        headers: {
            'Content-Type': 'application/json',
        }
    };

    const body = JSON.stringify({ uid, token, new_password, re_new_password });

    try {
        await axios.post(`${process.env.REACT_APP_API_URL}/auth/users/reset_password_confirm/`, body, config);
        return { success: true }
    } catch (err) {
        const res = err.response;
        if (res.data.uid || res.data.token) {
            return {
                success: false,
                message: "Something was wrong with your reset password link, please request another one.",
            }
        } else if (Object.keys(res.data).length !== 0) {       
            const message = combineMessages(res);     
            return {
                success: false,
                message: message,
            }
        } else {
            return {
                success: false,
                message: "Something went wrong!",
            }
        }
    }
}

export const logout = () => dispatch => {
    dispatch({
        type: LOGOUT
    });
}