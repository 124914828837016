import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useRef } from "react";
import axios from "axios";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import CropModal from "../components/CropModal";
import Container from "react-bootstrap/Container";
import Table from "react-bootstrap/Table";
import GameTableRow from "../components/GameTableRow";
import GameTableHeader from "../components/GameTableHeader";
import GamesFilter from "../components/GamesFilter";
import ProfileCard from "../components/ProfileCard";
import { sortByProperty, filterData, combineMessages } from "../util/util";
import Card from "react-bootstrap/Card";
import Image from "react-bootstrap/Image";
import { FaBell, FaCog, FaPlus } from "react-icons/fa";
import Tabs from "react-bootstrap/Tabs";

import Tab from "react-bootstrap/Tab";
import "./Profile.css";
import { Form, Button } from "react-bootstrap";
import { connect } from "react-redux";
import { load_user, checkAuthenticated } from "../actions/auth";
import EditUserForm from "../components/EditUserForm";

function Profile({
  load_user,
  user,
  isAuthenticated,
  access,
  checkAuthenticated,
}) {
  const { id } = useParams();
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [followed, setFollowed] = useState(false);
  const [userNotExist, setUserNotExist] = useState(false);
  const [username, setUsername] = useState("AnonymousUser");
  const [biography, setBiography] = useState("");
  const [displayMode, setDisplayMode] = useState("following");
  const [bannerProfile, setBannerProfile] = useState(`/images/default_pfp.png`); //default profile on load will change this state after upload
  const [profilePicture, setProfilePicture] = useState(
    `/images/default_pfp.png`
  );
  const bannerInputRef = useRef(null); //need this to reference banner onclick element later

  const [allUsersGames, setAllUsersGames] = useState([]); // all of users games, regardless of filters
  const [usersGames, setUsersGames] = useState([]); // users games after all the filters
  const [usersFavouriteGames, setUsersFavouriteGames] = useState([]);
  const [file, setFile] = useState(null);
  const [followButtonText, setFollowButtonText] = useState("Follow");
  const [followerFollowingData, setFollowerFollowingData] = useState(null);
  const [cropModalOpen, setCropModalOpen] = useState(false); //this will control the pop up for cropping
  const [crop, setCrop] = useState({
    unit: "px",
    width: 1500, // Fixed width
    height: 300, // Fixed height
    x: 0,
    y: 0,
  }); //this is the cropping moveable shape for usrs to iteract with
  const [selectedImageDataUrl, setSelectedImageDataUrl] = useState(null);
  const [imageRef, setImageRef] = useState(null);

  const [filters, setFilters] = useState({
    title__icontains: null,
    game_status: null,
    rating__gte: null,
    rating__lte: null,
    publishers__name: null,
    developers__name: null,
    platforms__name: null,
    genres__name: null,
    release_date__gte: null,
    release_date__lte: null,
    every: true,
  });
  const header_names = [
    "Title",
    "Image",
    "Status",
    "Score",
    "Platforms",
    "Genres",
    "Release Date",
  ];
  const fields = [
    "title",
    "thumbnail_url",
    "game_status",
    "rating",
    "platforms",
    "genres",
    "release_date",
  ];

  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  };

  const triggerBannerUpload = () => {
    bannerInputRef.current.click();
  };

  const handleFileChange = (event) => {
    //this is for profile picture upload
    const selectedFile = event.target.files[0];

    if (!selectedFile) {
      alert("Please select a file.");
      return;
    }

    const allowedTypes = ["image/jpeg", "image/png", "image/jph"];
    const maxSize = 5 * 1024 * 1024; // 5MB in bytes

    if (!allowedTypes.includes(selectedFile.type)) {
      alert("Allowed file types are: JPG, JPEG and PNG.");
      return;
    }

    if (selectedFile.size > maxSize) {
      alert(`The file size should not exceed ${maxSize / 1024 / 1024}MB.`);
      return;
    }

    handleSubmit(event, selectedFile);
  };

  const handleErrorResDisplay = (err, defaultMsg) => {
    const res = err.response;

    var message = defaultMsg;
    if (Object.keys(res.data).length !== 0) {
      message = combineMessages(res);
      if (
        res.data.hasOwnProperty("code") &&
        res.data.code === "token_not_valid"
      ) {
        checkAuthenticated();
        message = "Your login has expired. Please try again.";
      }
    }

    setShowNotification(true);
    setNotificationMessage(message);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  };

  const handleSubmit = async (event, fileToUpload = file) => {
    //for profile picture upload
    event.preventDefault();

    if (!fileToUpload) {
      alert("Please select a file first.");
      return;
    }

    const formData = new FormData();
    formData.append("profile_picture", fileToUpload, fileToUpload.name);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/${user.id}/upload_image/`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `JWT ${access}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const result = await response.json();

      const newImageUrl = `${process.env.REACT_APP_CDN_URL}/${username}/${result.url}`;
      setProfilePicture(newImageUrl);
      load_user();
    } catch (error) {
      console.error("Error during file upload:", error);
      handleErrorResDisplay(error, "Upload failed.");
    }
  };

  const onImageLoaded = (image) => {
    // console.log(image);
    //this is going to be an <img> element with the file that user picks
    setImageRef(image);
  };

  const onCropChange = (newCrop) => {
    //crop change just means every time usr moves the cropping shape
    setCrop((prevCrop) => ({
      ...newCrop, //previous data
      width: prevCrop.width, // Keep the initial width fixed
      height: prevCrop.height, // Keep the initial height fixed
    }));
  };

  const handleCropSubmit = async () => {
    //this is the button 'submit' after cropping
    if (imageRef && crop.width && crop.height) {
      //if image exists <img> and dimensions
      try {
        const croppedBlob = await getCroppedImg(imageRef, crop);

        // console.log("Cropped Blob:", croppedBlob);
        // Close the modal after processing the crop
        setCropModalOpen(false);
        uploadCroppedImage(croppedBlob); //after we cropped the image to proper dimensions and x y axis coords we upload blob
      } catch (e) {
        console.error("Crop processing error:", e);
        alert("Failed to process crop: " + e.message);
      }
    }
  };

  const getCroppedImg = (image, crop) => {
    const canvas = document.createElement("canvas");
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    // Clear the canvas with a transparent fill
    ctx.clearRect(0, 0, crop.width, crop.height);

    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          console.error("Canvas is empty");
          reject(new Error("Canvas is empty"));
          return;
        }
        blob.name = "croppedImage.png"; // Use .png to maintain transparency
        resolve(blob);
      }, "image/png"); // Specify PNG format to maintain transparency
    });
  };

  const handleBannerChange = async (event) => {
    //this is the onclick to open file
    const selectedFile = event.target.files[0];

    //TODO: crop banner not priority
    if (selectedFile) {
      // Check for file type
      const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!allowedTypes.includes(selectedFile.type)) {
        alert("Allowed file types are: JPEG, PNG, GIF.");
        return;
      }

      // Check for file size (example: 5MB limit)
      const maxSize = 5 * 1024 * 1024; // 5MB in bytes
      if (selectedFile.size > maxSize) {
        alert(`The file size should not exceed ${maxSize / 1024 / 1024}MB.`);
        return;
      }

      //after validation passes
      //try crop
      // Reading the file and setting it up for cropping
      const reader = new FileReader(); //read focntents of blob
      reader.onloadend = () => {
        // Set the read data URL for the selected image
        // console.log("here");
        setSelectedImageDataUrl(reader.result);
        setCropModalOpen(true);
        // console.log(reader.result);
      };

      reader.readAsDataURL(selectedFile); // this will trigger ^^ the onloaded
      // encoded string that represents the file's data,
      // allowing it to be used directly in web pages
    }
  };

  const uploadCroppedImage = async (croppedImage) => {
    const formData = new FormData();
    formData.append("banner", croppedImage, "croppedImage.jpeg");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/users/${user.id}/upload_banner/`,
        {
          method: "POST",
          body: formData,
          headers: {
            Authorization: `JWT ${access}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to upload cropped banner.");
      }

      const result = await response.json();

      const newImageUrl = `${process.env.REACT_APP_CDN_URL}${result.url}`;

      setBannerProfile(newImageUrl); // Update the banner URL with the uploaded image
      alert("Banner uploaded successfully!");
    } catch (err) {
      console.error("Error during cropped image upload:", err);
      handleErrorResDisplay(err, "Upload failed: " + err.message);
    }
  };
  const filterHandler = (newFilters) => setFilters(newFilters);
  const handleOrderSelect = (value, isReverse) => {
    const sortedGames = sortByProperty(usersGames, value, isReverse);
    setUsersGames([...sortedGames]);
    const allSortedGames = sortByProperty(allUsersGames, value, isReverse);
    setAllUsersGames([...allSortedGames]);
  };
  const fetchUserData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/users/${id}/get_useraccount/`,
        config
      );

      setBannerProfile(response.data.banner_picture);
      setProfilePicture(response.data.profile_picture);
      setUsername(response.data.username);
      setBiography(response.data.biography);
    } catch (err) {
      setUserNotExist(true);
    }
  };
  const fetchUsersGames = async () => {
    var url = `${process.env.REACT_APP_API_URL}/api/games/users_games_list/?username=${id}`;
    try {
      const response = await axios.get(url, config);
      return response.data;
    } catch (err) {
      setUserNotExist(true);
    }
  };
  const fetchFollowingsFollowers = async () => {
    let baseUrl = `${process.env.REACT_APP_API_URL}/api/game_users/get_followers_following/?username=${id}`;
    if (user) {
      //because need to see on load if user follows user and display button text
      baseUrl += `&loggeduser=${user.username}`;
    }
    try {
      const response = await axios.get(baseUrl, config);
      setFollowerFollowingData(response.data);
      setFollowed(response.data.is_following); // Update 'followed' based on the response
      setFollowButtonText(response.data.is_following ? "Unfollow" : "Follow"); // Set button text based on 'followed' state
    } catch (error) {
      console.error(error);
    }
  };
  const fetchUsersFavouriteGames = async () => {
    var url = `${process.env.REACT_APP_API_URL}/api/games/get_user_favourite_games/?username=${id}`;
    try {
      const response = await axios.get(url, config);
      return response.data;
    } catch (err) {
      setUserNotExist(true); //?
    }
  };

  const followUser = async () => {
    /*OnClick handler for users to follow users */
    try {
      const authConfig = {
        headers: {
          ...config.headers,
          ["Authorization"]: `JWT ${access}`,
        },
      };
      const url = `${process.env.REACT_APP_API_URL}/api/game_users/${user.user.id}/follow/`;
      const response = await axios.post(url, { username: id }, authConfig);
      if (response.status === 201) {
        setFollowed((prevState) => !prevState); // Toggle 'followed' state
        setFollowerFollowingData((prevData) => ({
          //updating the count in real timeaccording based on if follow or unfollow user
          ...prevData,
          followers_count: followed
            ? prevData.followers_count - 1
            : prevData.followers_count + 1,
        }));
        setFollowButtonText(followed ? "Follow" : "Unfollow"); // Update button text based on the new 'followed' state
      }
    } catch (error) {
      console.error("Error following the user:", error);
      handleErrorResDisplay(error, "Follow failed: " + error.message);
    }
  };
  useEffect(() => {
    fetchFollowingsFollowers();
  }, [user, id]);
  useEffect(() => {
    const fetchData = async () => {
      await fetchUserData();
      const favGames = await fetchUsersFavouriteGames();
      const games = await fetchUsersGames();

      if (games) {
        const sortedGames = sortByProperty(games, "game_status");
        setUsersGames([...sortedGames]);
        setAllUsersGames([...sortedGames]);
      }

      if (favGames) {
        setUsersFavouriteGames(favGames);
      }
    };
    fetchData();
  }, [id]);
  https: useEffect(() => {
    setUsersGames([...filterData(allUsersGames, filters)]);
  }, [filters, allUsersGames, profilePicture]);
  if (userNotExist) {
    return (
      <div style={{ minHeight: "100vh", color: "white" }}>
        That user does not exist.
      </div>
    );
  }

  let displayProfilePicture;
  if (user && user.username === id) {
    console.log(user.profile_picture, "user profile picture");
    displayProfilePicture = user.profile_picture;
  } else {
    displayProfilePicture = profilePicture;
  }

  return (
    <div style={{ minHeight: " 100vh" }}>
      <Container fluid className="px-0">
        <Card
          className="text-center border-0"
          style={{
            backgroundImage: `url(${bannerProfile})`,
            backgroundSize: "cover", // or "contain" depending on your preference
            backgroundPosition: "center", // Adjusts the position of the background image
            backgroundRepeat: "no-repeat", // Prevents the background image from repeating
            height: "230px",
          }}
        >
          {/*Banner upload */}
          {isAuthenticated && user && user.username === id && (
            <Button
              variant="outline-secondary"
              size="sm"
              className="p-1"
              onClick={triggerBannerUpload} // Use the function to trigger the file input click
              style={{
                position: "absolute",
                right: 10,
                bottom: "-17.5px",
                border: "none",
                background: "#D3D3D3",
                color: "#8E8E8E",
                borderRadius: "50%",
                width: "35px",
                height: "35px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaPlus />
            </Button>
          )}

          <Form.Control
            type="file"
            id="bannerUpload"
            ref={bannerInputRef}
            hidden
            onChange={handleBannerChange} // You'll define this function to handle banner changes
          />
          {selectedImageDataUrl && (
            <CropModal
              isOpen={cropModalOpen}
              onClose={() => setCropModalOpen(false)}
            >
              <ReactCrop
                src={selectedImageDataUrl}
                crop={crop}
                onChange={onCropChange}
                // onComplete={onCropComplete}
                onImageLoaded={onImageLoaded}
              />
              <Button onClick={handleCropSubmit}>Submit Banner</Button>
            </CropModal>
          )}

          {/*end*/}
          <Card.Body>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div style={{ position: "absolute", left: 100, bottom: -30 }}>
                {" "}
                {/* This div wraps the image and button */}{" "}
                <Image
                  key={user ? user.profile_picture : "default"}
                  src={displayProfilePicture || `/images/default_pfp.png`}
                  roundedCircle
                  style={{
                    width: "150px",
                    height: "150px",
                    objectFit: "cover", // Ensure the image covers the area, cropping if necessary
                    borderRadius: "50%", // Maintain the circular shape
                  }}
                />
                {isAuthenticated && user && user.username === id && (
                  <>
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      className="p-1"
                      onClick={() =>
                        document.getElementById("profilePictureUpload").click()
                      }
                      style={{
                        position: "absolute",
                        right: 0,
                        bottom: 12.5,
                        border: "none",
                        background: "#D3D3D3",
                        color: "#8E8E8E",
                        borderRadius: "50%",
                        width: "35px",
                        height: "35px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <FaPlus />
                    </Button>
                    <Form.Control
                      type="file"
                      id="profilePictureUpload"
                      hidden
                      onChange={handleFileChange}
                    />
                  </>
                )}
              </div>
              {/* <FaBell style={{ marginLeft: "auto", marginRight: "20px" }} />{" "}
              <FaCog /> */}
            </div>
          </Card.Body>
        </Card>
      </Container>
      <Container>
        <Card.Title
          style={{
            marginTop: "20px",
            marginLeft: "50px",
            color: "#ececec",
            fontSize: "78px",
            fontFamily: "Inter",
          }}
        >
          <div style={{ fontWeight: "bold" }}>
            {username}{" "}
            {user && user.username !== id && isAuthenticated && (
              <Button onClick={followUser}>{followButtonText}</Button>
            )}
          </div>
          {followerFollowingData && (
            <div style={{ fontSize: "16px", marginTop: "0px" }}>
              Followers: {followerFollowingData.followers_count} Following:{" "}
              {followerFollowingData.following_count}
            </div>
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {biography && (
              <div style={{ fontSize: "24px", marginLeft: "20%" }}>
                {biography}
              </div>
            )}
          </div>
        </Card.Title>
        <Tabs
          defaultActiveKey="elist"
          id="uncontrolled-tab-example"
          className="black-font-tabs"
        >
          {" "}
          <Tab eventKey="elist" title="eList">
            <div style={{ marginBottom: "20px", marginTop: "20px" }}>
              <GamesFilter
                filterHandler={filterHandler}
                orderHandler={() => {}}
                isUserList={true}
              />
            </div>
            <Table striped bordered hover responsive className="user-table">
              <GameTableHeader
                header_names={header_names}
                fields={fields}
                unorderable={["thumbnail_url"]}
                orderHandler={handleOrderSelect}
              />
              <tbody>
                {usersGames.map((game) => (
                  <GameTableRow
                    users_game={game}
                    fields={fields}
                    key={game.id}
                  />
                ))}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="favourites" title="Favourites">
            <Table
              striped
              bordered
              hover
              responsive
              className="user-table"
              style={{ marginTop: "90px" }}
            >
              <GameTableHeader
                header_names={header_names}
                fields={fields}
                unorderable={["thumbnail_url"]}
                orderHandler={handleOrderSelect}
              />
              <tbody>
                {usersFavouriteGames.map((game) => (
                  <GameTableRow
                    users_game={game}
                    fields={fields}
                    key={game.id}
                  />
                ))}
              </tbody>
            </Table>

            {/* <Favourites /> */}
          </Tab>
          {
            <Tab eventKey="social" title="Social">
              <div>
                <Form>
                  <Form.Check
                    classname="my-custom-switch"
                    type="switch"
                    style={{ marginTop: "15px", color: "#ececec" }}
                    id="custom-switch"
                    label={
                      displayMode === "following" ? "Following" : "Followers"
                    }
                    onChange={() =>
                      setDisplayMode(
                        displayMode === "following" ? "followers" : "following"
                      )
                    }
                    checked={displayMode === "following"}
                  />
                </Form>
              </div>

              <div className="profile-cards-container">
                {followerFollowingData &&
                  (displayMode === "following"
                    ? followerFollowingData.following.map((item, _) => (
                        <ProfileCard
                          key={_}
                          imageUrl={item.following_profile_picture}
                          username={item.following_username}
                          usernameColor={"#ececec"}
                        />
                      ))
                    : followerFollowingData.followers.map((item) => (
                        <ProfileCard
                          imageUrl={item.follower_profile_picture}
                          username={item.follower_username}
                          usernameColor={"#ececec"}
                        />
                      )))}
              </div>
            </Tab>
          }
          {isAuthenticated && user && user.username === id && (
            <Tab eventKey="edit" title="Edit">
              <EditUserForm
                user={user}
                access={access}
                load_user={load_user}
                checkAuthenticated={checkAuthenticated}
                resetBiography={(b) => {
                  setBiography(b);
                }}
              />
            </Tab>
          )}
        </Tabs>
        {showNotification && (
          <div className="notification">{notificationMessage}</div>
        )}
      </Container>
    </div>
  );
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  access: state.auth.access,
});

export default connect(mapStateToProps, { load_user, checkAuthenticated })(
  Profile
);
