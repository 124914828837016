import React from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

function GameTableRow({ users_game, fields, SLUG_COL = 0, IMAGE_COLS=[1], size="t_cover_small_2x" }) {
    const getFieldValue = (field_name) => {
        const value = users_game[field_name];
        if (Array.isArray(value)) {
            return value.map( e => e.name ).join(', ');
        } else if (field_name == "thumbnail_url") {
            return value.replace("t_thumb", size);  
        } else {
            return value;
        }
    }

    return (
        <tr>
            {
                fields.map( (field_name, i) => (
                    (IMAGE_COLS.includes(i)) ? (
                        <td key = { users_game.id + "." + field_name }>
                            <img src={getFieldValue(field_name)} alt={`${users_game['title']} cover image`} height='160px' style={{'max-width': '100%'}} />
                        </td>
                    ) : (
                        <td key = { users_game.id + "." + field_name }>
                            { 
                                (i == SLUG_COL) ? (
                                    <Link to={`/game/${users_game.id}/${users_game.slug}`}>
                                        { getFieldValue(field_name) }
                                    </Link> ) : (
                                        getFieldValue(field_name)
                                    )
                            }
                        </td>
                    )
                ))
            }
        </tr>
    );
}

export default GameTableRow;
