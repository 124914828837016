import React from "react";
import { Form, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Forum.css";
import { load_user, checkAuthenticated } from "../actions/auth";
import moment from "moment";

import { connect } from "react-redux";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { FaComment, FaEye, FaPlay, FaThumbsUp } from "react-icons/fa";
import { combineMessages } from "../util/util";

const Forum = ({
  load_user,
  user,
  isAuthenticated,
  access,
  checkAuthenticated,
}) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [title, setTitle] = useState(""); //this is new thread title
  const [searchTerm, setSearchTerm] = useState("");
  const [content, setContent] = useState(""); //this is new thread content
  const [threads, setThreads] = useState({});
  const [show, setShowModal] = useState(false);
  const [category, setCategory] = useState(null); //this is thread category
  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => setShowModal(true);
  //   console.log(access);
  const navigate = useNavigate();
  const config = {
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `JWT ${access}`,
    },
  };
  const handleSearchSubmit = async (event) => {
    event.preventDefault(); // Prevent the default form submit action refreshung
    // console.log("Submitting search term:", searchTerm);
    const url = `${process.env.REACT_APP_API_URL}/api/forum/search/?search_term=${searchTerm}`;

    const response = await axios.get(url);
    setThreads(response.data);
    // console.log(response);
  };

  const submitNewThread = async (event) => {
    event.preventDefault();
    const data = { title, content, category };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/forum/create_thread/`,
        data,
        config
      );

      if (response.status === 201) {
        // alert("submitted");
        // console.log("Thread created successfully:", response.data);

        // Handle successful creation here (e.g., redirecting, showing a success message)
        handleCloseModal();
        navigate(`/forum/${response.data.id}`);
      }
    } catch (error) {
      console.log(error);
      const res = error.response;

      var message = "Something went wrong while creating your thread.";
      if (Object.keys(res.data).length !== 0) {
        message = combineMessages(res);
        if (
          res.data.hasOwnProperty("code") &&
          res.data.code === "token_not_valid"
        ) {
          checkAuthenticated();
          message = "Your login has expired. Please try again.";
        }
      }

      setNotificationMessage(message);
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000);
    }
  };
  useEffect(() => {
    const fetchThreads = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/forum/?sort=likes`;

      try {
        const response = await axios.get(url);

        // console.log(response);
        setThreads(response.data.results);
      } catch (err) {
        console.log(err);
      }
    };

    fetchThreads();
  }, []);

  // console.log(threads);
  return (
    <div>
      <div
        style={{
          minHeight: "100vh",
          color: "white",
          margin: "0px 150px 0px 150px",
          paddingBottom: "50px",
          paddingTop: "150px",
          backgroundImage: "url(/images/logo.svg)",
          backgroundSize: "40% 400px",
          backgroundPosition: "top -100px center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <div
          className="search-container"
          style={{ position: "relative", width: "300px", marginLeft: "auto" }}
        >
          <Form onSubmit={handleSearchSubmit}>
            <Form.Group className="mb-3">
              <Form.Control
                type="text"
                placeholder="search threads"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </Form.Group>
            {/* <button type="submit">Search</button> */}
          </Form>
        </div>
        <div
          style={{
            backgroundColor: "#ecede7",
            padding: "20px",
            minHeight: "100vh",
          }}
        >
          {" "}
          <div id="main-content" style={{ color: "#00181f" }}>
            <h1>Welcome to eList Discussions</h1>
            <p style={{ backgroundColor: " #e3d5e2", padding: "10px" }}>
              <b>Open to all!</b> eList Discussions welcome every participant,
              whether you're a newcomer or a seasoned contributor.
            </p>
            <p style={{ backgroundColor: " #e3d5e2", padding: "10px" }}>
              <b>Research before posting!</b> It's wise to utilize the search
              function to check if your topic has already been addressed.
            </p>
            <p style={{ backgroundColor: " #e3d5e2", padding: "10px" }}>
              <b>Maintain civility!</b> Should you encounter any disruptive
              behavior like spamming or harassment, please report the incident,
              and our team will review it.
            </p>

            <div
              className="group_tabs"
              style={{ borderBottom: "3px solid white" }}
            >
              <div className="group_tabs_scroll">
                {/* <a id="tab_general" className="group_tab active" href="">
              <span>eList Forums</span>
            </a>
            <a id="tab_games" className="group_tab" href="">
              <span>Game Forums</span>
            </a>
            <a id="tab_tools" className="group_tab" href="">
              <span>Feature recommendations</span>
            </a> */}
              </div>
            </div>
          </div>
          <div className="create-thread " style={{ marginTop: "10px" }}>
            {isAuthenticated && (
              <Button onClick={handleShowModal}>Create thread</Button>
            )}

            <Modal show={show} onHide={handleCloseModal}>
              <Modal.Header style={{ backgroundColor: "#e3d5e2" }} closeButton>
                <Modal.Title>Create New Thread</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={submitNewThread}>
                  <Form.Group controlId="threadTitle">
                    <Form.Label style={{ color: "black" }}>Title</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter thread title"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="threadCategory">
                    <Form.Label style={{ color: "black" }}>Category</Form.Label>
                    <Form.Control
                      as="select"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      // required
                    >
                      <option value="">Select a category</option>{" "}
                      {/* Add a default option */}
                      <option value="Recommendations">Recommendations</option>
                      <option value="Help">Help</option>
                      <option value="Mobile">Mobile</option>
                      <option value="Discussions">Discussion</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="threadContent">
                    <Form.Label style={{ color: "black" }}>Content</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter thread content"
                      value={content}
                      onChange={(e) => setContent(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    style={{ marginTop: "3px" }}
                  >
                    Create Thread
                  </Button>
                </Form>
              </Modal.Body>
            </Modal>
          </div>
          <div className="display-threads">
            <div style={{ backgroundColor: "#e3d5e2" }}>
              <h3 style={{ color: "black" }}>
                {" "}
                <FaPlay size={10} style={{ color: "#9758d7" }} /> Threads
              </h3>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: "black",
                paddingLeft: "10px",
                paddingRight: "10px",
              }}
            >
              <span style={{ flex: 4 }}>DISCUSSION</span>
              <span style={{ flex: 1, textAlign: "center" }}>REPLIES</span>
              <span style={{ flex: 1, textAlign: "center" }}>VIEWS</span>
              <span style={{ flex: 1, textAlign: "center" }}>LIKES</span>
              <span style={{ flex: 1, textAlign: "right" }}>MOST RECENT</span>
            </div>

            {threads &&
              threads.length > 0 &&
              threads.map((thread) => (
                <div
                  key={thread.id}
                  className="thread-summary"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "10px",
                    paddingRight: "10px",
                  }}
                >
                  <div style={{ flex: 4 }}>
                    <p>
                      <Link to={`/forum/${thread.id}`} className="thread-link">
                        {thread.category && `[${thread.category}]`}{" "}
                        {thread.title}
                      </Link>
                    </p>
                    <p style={{ fontSize: "13px", color: "#686c6e" }}>
                      Created by: {thread.creator_username} on{" "}
                      {new Date(thread.created_at).toLocaleDateString()}
                    </p>
                  </div>
                  <div style={{ flex: 1, textAlign: "center" }}>
                    <span>{thread.posts_count}</span>
                    <FaComment style={{ color: "gray", marginLeft: "4px" }} />
                  </div>
                  <div style={{ flex: 1, textAlign: "center" }}>
                    <span>
                      {thread.views} <FaEye style={{ color: "gray" }} />
                    </span>
                  </div>
                  <div style={{ flex: 1, textAlign: "center" }}>
                    <span style={{ marginRight: "4px" }}>{thread.likes}</span>
                    <FaThumbsUp style={{ color: "gray" }} />
                  </div>
                  <div style={{ flex: 1, textAlign: "right" }}>
                    <span>{moment(thread.last_updated).fromNow()}</span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {showNotification && (
        <div className="notification">{notificationMessage}</div>
      )}
    </div>
  );
};

// export default Forum;
const mapStateToProps = (state) => ({
  user: state.auth.user,
  isAuthenticated: state.auth.isAuthenticated,
  access: state.auth.access,
});

export default connect(mapStateToProps, { load_user, checkAuthenticated })(
  Forum
);
