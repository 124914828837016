import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBIcon,
} from "mdb-react-ui-kit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Footer.css";
import { Navbar } from "react-bootstrap";
import {
  faFacebookF,
  faTwitter,
  faReddit,
  faInstagram,
  faDiscord,
  faTiktok,
} from "@fortawesome/free-brands-svg-icons";
const Footer = () => {
  return (
    <MDBFooter className="text-center text-lg-start text-white-50 custom-footer-bg">
      {" "}
      <section className="d-flex justify-content-center justify-content-lg-between p-4 border-bottom">
        <div className="me-5 d-none d-lg-block text-white">
          {" "}
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href="" className="me-4 text-reset">
            <FontAwesomeIcon icon={faFacebookF} size="lg" />
          </a>
          <a href="" className="me-4 text-reset">
            <FontAwesomeIcon icon={faTwitter} className="icon" size="lg" />
          </a>
          <a href="" className="me-4 text-reset">
            <FontAwesomeIcon icon={faDiscord} className="icon" size="lg" />
          </a>
          <a href="" className="me-4 text-reset">
            <FontAwesomeIcon icon={faInstagram} className="icon" size="lg" />
          </a>
          <a href="" className="me-4 text-reset">
            <FontAwesomeIcon icon={faTiktok} className="icon" size="lg" />
          </a>
          <a href="" className="me-4 text-reset">
            <FontAwesomeIcon icon={faReddit} className="icon" size="lg" />
          </a>
        </div>
      </section>
      <section className="">
        <MDBContainer className="text-center text-md-start mt-5">
          <MDBRow className="mt-3">
            <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4 text-white">
              <h6 className="text-uppercase fw-bold mb-4">
                <Navbar.Brand>
                  <img
                    src="/images/logo.svg"
                    width="150"
                    height="60"
                    alt="Logo"
                  />
                </Navbar.Brand>
              </h6>
              <p></p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Home Page</h6>
              <p>
                <a
                  href="/browse?filter=recent_games"
                  className="link text-reset "
                >
                  Recent Games
                </a>
              </p>
              <p>
                <a
                  href="/browse?filter=top_rated_games"
                  className="link text-reset"
                >
                  Top Rated Games
                </a>
              </p>

              <p>
                <a
                  href="/browse?filter=top_rated_this_year"
                  className="link text-reset"
                >
                  Top Rated Games This Year
                </a>
              </p>
            </MDBCol>

            <MDBCol md="3" lg="2" xl="2" className="mx-auto mb-4">
              <h6 className="text-uppercase fw-bold mb-4">About Us</h6>
              <p>
                <a href="/story" className=" link text-reset">
                  Our Story
                </a>
              </p>
              <p>
                <a href="/faq" className="link text-reset">
                  FAQ
                </a>
              </p>
              <p>
                <a href="/cookies" className="link text-reset">
                  Cookie Preferences
                </a>
              </p>
              <p>
                <a href="/patchnotes" className="link text-reset">
                  Patch Notes
                </a>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className="mx-auto mb-md-0 mb-4">
              <h6 className="text-uppercase fw-bold mb-4">Contact</h6>

              <p>
                <MDBIcon color="secondary" icon="envelope" /> support@elist.gg
              </p>
              <p>
                <MDBIcon color="secondary" icon="phone" /> + 1 647 102 2391
              </p>
              <p>
                <MDBIcon color="secondary" icon="print" /> + 1 647 122 2391
              </p>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div className="text-center p-4" style={{ backgroundColor: "#242226" }}>
        {" "}
        © 2024 Copyright:
        <a className="text-reset fw-bold link" href="https://elist.gg/">
          {" "}
          eList.gg
        </a>
      </div>
    </MDBFooter>
  );
};

export default Footer;
