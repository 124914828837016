import React, { Fragment, useState, useEffect, useRef } from "react";
import { Container, Navbar, Nav } from "react-bootstrap"; // import Form
import "bootstrap/dist/css/bootstrap.min.css";
import "./AppNavbar.css";
import Image from "react-bootstrap/Image";
import { Link, useLocation } from "react-router-dom";
import { FaUser } from "react-icons/fa"; // import icons
import SearchBar from "./SearchBar";

import { connect } from "react-redux";
import { logout } from "../actions/auth";
import SearchResultsList from "./SearchResultsList";

const AppNavbar = ({ logout, isAuthenticated, user }) => {
  let location = useLocation();

  const searchContainerRef = useRef(null);
  const [showResults, setShowResults] = useState(false);
  const [gameResults, setGameResults] = useState([]);
  const [userResults, setUserResults] = useState([]);
  // console.log("HERE", user);
  const navbarStyle = {
    backgroundColor: "#1A1A1B",
    fontFamily: "'Inter', sans-serif",
    color: "#959595",
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(event.target)
      ) {
        setShowResults(false);
      } else if (searchContainerRef.current) {
        setShowResults(true);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const guestLinks = () => (
    <Fragment>
      <Nav.Link
        as={Link}
        to="/login"
        style={{
          backgroundColor: "#2A2A2A",
          color: "#ececec",
          borderRadius: "25px 25px 25px 25px",
          padding: "0px 20px",
          height: "35px", // set a specific height
          lineHeight: "35px", // set line height equal to height for vertical centering
          marginRight: "20px",
        }}
      >
        LOG IN
      </Nav.Link>
      <Nav.Link
        as={Link}
        to="/signup"
        style={{
          backgroundColor: "#9758d7",
          color: "#ececec",
          borderRadius: "25px 25px 25px 25px",
          padding: "0px 20px",
          height: "35px", // set a specific height
          lineHeight: "35px", // set line height equal to height for vertical centering
        }}
      >
        SIGN UP
      </Nav.Link>
    </Fragment>
  );

  const authLinks = () => (
    <Fragment>
      <Nav.Link
        as={Link}
        to="/"
        onClick={logout}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#9758d7",
          color: "#ececec",
          borderRadius: "25px",
          padding: "0px 20px",
          height: "35px",
          marginRight: "20px",
        }}
      >
        LOG OUT
      </Nav.Link>

      <Nav.Link
        as={Link}
        to={user ? "/profile/" + user.username : "/"}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "35px",
        }}
      >
        {user && user.profile_picture ? (
          <Image
            src={user.profile_picture}
            roundedCircle
            style={{ height: "35px", width: "35px" }}
          />
        ) : (
          <FaUser />
        )}
      </Nav.Link>
    </Fragment>
  );

  return (
    <Navbar expand="lg" style={navbarStyle}>
      <Container className="justify-content-center">
        <Navbar.Brand as={Link} to="/">
          <img
            src="/images/logo.svg"
            width="150"
            height="40"
            className="d-inline-block align-top"
            alt="Logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto ms-auto">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div className="search-bar-container" ref={searchContainerRef}>
                <SearchBar
                  setGameResults={setGameResults}
                  setUserResults={setUserResults}
                />
                <SearchResultsList
                  gameResults={gameResults}
                  userResults={userResults}
                  showResults={showResults}
                />
              </div>
              <Nav.Link
                as={Link}
                to="/browse"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
              >
                Browse
              </Nav.Link>

              <Nav.Link
                as={Link}
                to="/forum"
                style={{ marginLeft: "10px", fontWeight: "bold" }}
              >
                Community
              </Nav.Link>
            </div>
          </Nav>
          <Nav>{isAuthenticated ? authLinks() : guestLinks()}</Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, { logout })(AppNavbar);
