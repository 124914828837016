import React, { useState } from "react";
import { connect } from "react-redux";
import { useParams, Link, Navigate } from "react-router-dom";
import { verify } from "../actions/auth";

const Activate = ({ verify, isAuthenticated }) => {
  const { uid, token } = useParams();
  const [verified, setVerified] = useState(false);

  const verify_account = (e) => {
    verify(uid, token);
    setVerified(true);
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div
      className="signup-container"
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        color: "white",
        fontFamily: "Inter",
      }}
    >
      <div className="signup-form center-form">
        {!verified ? (
          <>
            <h1 style={{ textAlign: "center" }}>Activate your account</h1>
            <button
              onClick={verify_account}
              style={{
                marginTop: "50px",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              className="submit-button"
            >
              Verify
            </button>
          </>
        ) : (
          <div style={{ textAlign: "center" }}>
            <p>
              Your account has been activated!{" "}
              <Link to="/login" className="forgot-password-link">
                Log in
              </Link>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { verify })(Activate);
