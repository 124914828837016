import React, { useEffect, useState } from "react";
import ArrowLink from "../components/ArrowLink";
import GameCard from "../components/GameCard";
import TagButton from "../components/TagButton";
import { connect } from "react-redux";
import { load_user } from "../actions/auth";
import axios from "axios";
import "./Home.css";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import "./Signup.css";

// import { BackgroundBeams } from "../components/ui/background-beams";
import WelcomeBox from "../components/WelcomeBox";

function Home({ isAuthenticated }) {
  const navigate = useNavigate();
  const [recentGames, setRecentGames] = useState([]);
  const [topRatedGamesAllTime, setTopRatedGamesAllTime] = useState([]);
  const [topRatedGamesThisYear, setTopRatedGamesThisYear] = useState([]);

  // Assume each game card has a width of 200px + 20px grid gap
  const cardWidth = 220; // Width + grid gap
  const containerPadding = 600; // Total padding of the container (left + right)
  const maxRows = 2;

  const containerWidth = document.body.clientWidth - containerPadding;
  const cardsPerRow = Math.floor(containerWidth / cardWidth);
  const numberOfGamesToShow = cardsPerRow * maxRows;

  const genreOnClick = (genreName) => {
    navigate(`/browse?genres__name=${encodeURIComponent(genreName)}`);
  };

  useEffect(() => {
    const fetchRecentGames = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/games/recent_games_home/?num_games=${numberOfGamesToShow}`
      );
      setRecentGames(response.data);
    };

    const fetchTopRatedGamesAllTime = async () => {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/games/top_rated_games_all_time_home/?num_games=${numberOfGamesToShow}`
      );
      setTopRatedGamesAllTime(response.data);
    };

    const fetchTopRatedGamesThisYear = async () => {
      // Add this function
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/games/top_rated_games_this_year_home/?num_games=${numberOfGamesToShow}`
      );
      setTopRatedGamesThisYear(response.data);
    };

    fetchRecentGames();
    fetchTopRatedGamesAllTime();
    fetchTopRatedGamesThisYear();
  }, []);

  return (
    <div className="bg-colour  " style={{ padding: "20px 0" }}>
      <div style={{ padding: "0 300px" }} className="relative z-10 ">
        {" "}
        {!isAuthenticated ? <WelcomeBox /> : null}
        <div
          className="relative z-10 "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1
            className="relative z-10 "
            style={{
              fontFamily: '"Inter", sans-serif',
              fontSize: "20px",
              textIndent: "10px",
              whiteSpace: "nowrap",
              color: "#dcd3d3",
            }}
          >
            RECENT GAMES
          </h1>
          <ArrowLink link="/browse?filter=recent" direction="right" />{" "}
          {/* Use ArrowLink */}
        </div>{" "}
        <div
          className="relative z-10 "
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gridGap: "20px",
          }}
        >
          {recentGames.map((game, _) => (
            <div
              className="relative z-10 "
              style={{
                width: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <GameCard
                className="relative z-10 "
                id={game.id}
                key={_ + "3"}
                title={game.title}
                imageUrl={game.thumbnail_url}
                slug={game.slug}
                titleColor="#E6E6E6"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  marginTop: "40px",
                }}
              >
                {game.genres.map((genre) => (
                  <TagButton
                    label={genre.name}
                    color="#737076"
                    textColor="#CACACA"
                    hoverColor="#9758d7"
                    margin="-40px 5px 50px 0"
                    hoverTextColor="#ffffff"
                    onClick={() => genreOnClick(genre.name)}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className="relative z-10 "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1
            className="relative z-10 "
            style={{
              fontFamily: '"Inter", sans-serif',
              fontSize: "20px",
              textIndent: "10px",
              whiteSpace: "nowrap",
              color: "#dcd3d3",
            }}
          >
            TOP RATED GAMES
          </h1>
          <ArrowLink link="/browse?filter=top_rated" direction="right" />{" "}
          {/* Use ArrowLink */}
        </div>
        <div
          className="relative z-10 "
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gridGap: "20px",
          }}
        >
          {topRatedGamesAllTime.map((game) => (
            <div
              className="relative z-10 "
              style={{
                width: "200px",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <GameCard
                className="relative z-10 "
                id={game.id}
                title={game.title}
                key={game.title}
                imageUrl={game.thumbnail_url}
                slug={game.slug}
                titleColor="#E6E6E6"
              />
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  marginTop: "40px",
                  marginLeft: "20px",
                }}
              >
                {game.genres.map((genre) => (
                  <TagButton
                    label={genre.name}
                    color="#737076"
                    textColor="#CACACA"
                    hoverColor="#9758d7"
                    hoverTextColor="#ffffff"
                    margin="-40px 5px 50px 0"
                    onClick={() => genreOnClick(genre.name)}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
        <div
          className="relative z-10 "
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h1
            className="relative z-10 "
            style={{
              fontFamily: '"Inter", sans-serif',
              fontSize: "20px",
              textIndent: "10px",
              whiteSpace: "nowrap",
              color: "#dcd3d3",
            }}
          >
            TOP RATED GAMES THIS YEAR
          </h1>
          <ArrowLink
            link="/browse?filter=top_rated_this_year"
            direction="right"
          />{" "}
          {/* Use ArrowLink */}
        </div>
        <div
          className="relative z-10 "
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
            gridGap: "20px",
          }}
        >
          {topRatedGamesThisYear.map((game, _) => {
            // console.log(game);
            return (
              <div
                key={_}
                style={{
                  width: "200px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <GameCard
                  id={game.id}
                  title={game.title}
                  imageUrl={game.thumbnail_url}
                  slug={game.slug}
                  titleColor="#E6E6E6"
                  key={game.title}
                />
                <div
                  className="relative z-10 "
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    marginTop: "40px",
                    marginLeft: "20px",
                  }}
                >
                  {game.genres.map((genre) => (
                    <TagButton
                      label={genre.name}
                      color="#737076"
                      textColor="#CACACA"
                      hoverColor="#9758d7"
                      hoverTextColor="#ffffff"
                      margin="-40px 5px 50px 0"
                      onClick={() => genreOnClick(genre.name)}
                    />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
        {/* <BackgroundBeams /> */}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { load_user })(Home);
