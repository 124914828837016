import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, Navigate } from "react-router-dom";
import { connect } from "react-redux";
import { login } from "../actions/auth";
import "./Login.css"; // Make sure to create a Login.css file for styling
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';


const Login = ({ login, isAuthenticated, loginFailed, loginFailedMessage }) => {
  const recaptcha = useRef();
  const [loggingIn, setLoggingIn] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [keepSignedInChecked, setKeepSignedInChecked] = useState(true);
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });
  const [failedLogins, setFailedLogins] = useState(0);

  const { username, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });
  const handleKeepSignedInCheckChange = (e) =>
    setKeepSignedInChecked(e.target.checked);

  const onSubmit = (e) => {
    e.preventDefault();

    const doLogin = async () => {
      await login(username, password, keepSignedInChecked);
      setRequestSent(true);
      setFailedLogins(prevFailed => prevFailed + 1);
    };

    if (failedLogins >= 5) {
      const captchaValue = recaptcha.current.getValue()
      if (!captchaValue) {
        alert('Please verify the reCAPTCHA!');
      } else {
        const config = {
          headers: {
            'Content-Type': 'application/json'
          }
        };
        const body = JSON.stringify({ token: captchaValue });

        setLoggingIn(true);
        axios.post(`${process.env.REACT_APP_API_URL}/verify-recaptcha/`, body, config).then(async response => {
          if (response.data.success) {
            await doLogin();
          } else {
            setRequestSent(false);
            alert('Recaptcha not verified');
          }
        }).catch((err) => {
          console.log(err);
          setRequestSent(false);
          alert('Recaptcha not verified');
        }).finally(() => {
          setLoggingIn(false);
          if (recaptcha.current)
            recaptcha.current.reset();
        });
      }
    } else {
      setLoggingIn(true);
      doLogin().finally(() => setLoggingIn(false));
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }
  return (
    <div className="login-container">
      <div className="login-form">
        <h1>Sign In</h1>
        {requestSent && loginFailed && !loggingIn && (
          <p className="login-error">{loginFailedMessage}</p>
        )}
        {loggingIn ? (<div>Logging In...</div>) : (
          <Form onSubmit={onSubmit}>
            <Form.Group controlId="formBasicUsername">
              <Form.Label>Username</Form.Label>
              <Form.Control
                type="text"
                placeholder="Username"
                name="username"
                value={username}
                onChange={onChange}
                required
                className="input-field"
                style={{ marginBottom: "2rem" }}
              />
            </Form.Group>

            <Form.Group
              controlId="formBasicPassword"
              style={{ marginBottom: "2rem" }}
            >
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Password"
                name="password"
                value={password}
                onChange={onChange}
                required
                className="input-field"
              />
            </Form.Group>

            <Form.Group
              controlId="formBasicCheckbox"
              style={{
                marginBottom: "2rem",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Form.Check
                type="checkbox"
                label="Remember me?"
                checked={keepSignedInChecked}
                onChange={handleKeepSignedInCheckChange}
              />
              <Link to="/reset-password" className="forgot-password-link">
                Forgot password?
              </Link>{" "}
            </Form.Group>

            <Button variant="primary" type="submit" className="login-button">
              Sign In
            </Button>
            {(failedLogins >= 5) &&
              <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
            }
          </Form>)}
        {!loggingIn && (
          <div className="login-footer">
            Not a member? <Link to="/signup">Create an account</Link>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginFailed: state.auth.loginFailed,
  loginFailedMessage: state.auth.loginFailedMessage,
});

export default connect(mapStateToProps, { login })(Login);
