import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { reset_password } from "../actions/auth";

const ResetPassword = ({ isAuthenticated }) => {
  const [requestSent, setRequestSent] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [requestFailureMessage, setRequestFailureMessage] = useState("");
  const [formData, setFormData] = useState({
    email: "",
  });

  const { email } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    const res = await reset_password(email);
    if (res.success) {
      setRequestSent(true);
      setRequestFailed(false);
      setRequestFailureMessage("");
    } else {
      setRequestFailed(true);
      setRequestSent(false);
      setRequestFailureMessage(res.message);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className="signup-container">
      <Form onSubmit={(e) => onSubmit(e)} className="signup-form">
        <h1>Reset Password</h1>
        {requestFailed && (
          <p className="signup-error">{requestFailureMessage}</p>
        )}
        {!requestSent ? (
          <>
            <Form.Group className="form-group" controlId="email">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => onChange(e)}
                required
                className="form-control"
              />
            </Form.Group>
            <Button variant="primary" type="submit" className="submit-button">
              Submit
            </Button>
          </>
        ) : (
          <div className="signup-footer">
            <p>
              We've sent an email to {email} containing details on how to reset
              your password, if there is an account associated with that email.
            </p>
            <p>
              <Link to="/" className="forgot-password-link">
                Return home
              </Link>
            </p>
          </div>
        )}
      </Form>
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(ResetPassword);
