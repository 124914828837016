import React, { useEffect, useState } from "react";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./Browse.css";
import TagButton from "../components/TagButton";
import GamesFilter from "../components/GamesFilter";

import { filterSetToUrl, urlToFilterSet } from "../util/util";

function Browse() {
  // Constants for calculating items per page
  const cardWidth = 220; // Width + grid gap
  const containerPadding = 600; // Total padding of the container (left + right)
  const maxRows = 5;

  const NON_GENRE_FILTERS = ["recent", "top_rated", "top_rated_this_year"];
  const [games, setGames] = useState([]);

  const [order, setOrder] = useState(null);
  const [reverse, setReverse] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const query = new URLSearchParams(location.search);
  const [filter, setFilter] = useState(query.get("filter")); // NOTE this is just the genre name or other filter types rn, should probably rename this later
  const [itemsPerPage, setItemsPerPage] = useState(null);
  const [page, setPage] = useState(
    query.get("page") && !isNaN(query.get("page"))
      ? parseInt(query.get("page"))
      : 1
  );
  const [filterSet, setFilterSet] = useState(urlToFilterSet(location.search));
  const [usingFilterSet, setUsingFilterSet] = useState(false);
  const GameCard = React.lazy(() => import("../components/GameCard"));

  const filterHandler = async (filterSet) => {
    updateFrontendUrl({ fs: filterSet, pg: 1 });
  };

  const orderHandler = async (order, reverse) => {
    updateFrontendUrl({ o: order, r: reverse, pg: 1 });
  };

  useEffect(() => {
    const updateItemsPerPage = () => {
      const containerWidth = document.body.clientWidth - containerPadding;
      const cardsPerRow = Math.max(1, Math.floor(containerWidth / cardWidth));
      const numberOfGamesToShow = cardsPerRow * maxRows;
      setItemsPerPage(numberOfGamesToShow);
      //console.log("hi", numberOfGamesToShow);
    };
    // Calculate items per page once on mount
    updateItemsPerPage();
  }, []);

  const updateFrontendUrl = ({
    fs = null,
    pg = null,
    fl = null,
    o = null,
    r = null,
  }) => {
    const localPage = pg ? pg : page;
    const localFilterSet = fs ? fs : filterSet;
    const localFilter = fl ? fl : filter;
    const localOrder = o ? o : order;
    const localReverse = r !== null ? r : reverse;

    const baseUrl = `/browse`; // Ensure this is the base path you intend to use
    const filterUrl = filterSetToUrl(localFilterSet);
    let queryParams = [];

    if (filterUrl && filterUrl.length > 0) {
      queryParams.push(filterUrl);
    } else if (
      localFilter &&
      NON_GENRE_FILTERS.includes(localFilter) &&
      !localOrder &&
      localReverse == null
    ) {
      // Correctly use `?filter=` only if there is a filter to apply
      queryParams.push(`filter=${localFilter}`);
    }

    if (localOrder) {
      queryParams.push(`order=${localOrder}`);
    }

    if (localReverse !== null) {
      queryParams.push(`reverse=${localReverse}`);
    }

    // Ensure page parameter is appended correctly without repeating the base path
    if (localPage && !isNaN(localPage)) {
      queryParams.push(`page=${localPage}`);
    }

    const newUrl = `${baseUrl}?${queryParams.join("&")}`;
    navigate(newUrl, { replace: true }); // Use replace to avoid pushing multiple entries in the history
  };

  // Make sure to add itemsPerPage as a dependency to useEffect if needed
  useEffect(() => {
    const fetchGames = async () => {
      setIsLoading(true);
      try {
        let baseUrl = `${process.env.REACT_APP_API_URL}/api/games/`;
        let queryParams = [];
        let usingFilter = false;

        if (filter && NON_GENRE_FILTERS.includes(filter)) {
          usingFilter = true;
          setUsingFilterSet(false);
          queryParams.push(`filter=${encodeURIComponent(filter)}`);
        } else if (filterSet && Object.keys(filterSet).length > 0) {
          setUsingFilterSet(true);
          const filterUrl = filterSetToUrl(filterSet);
          if (filterUrl) queryParams.push(filterUrl);
        }

        // Include itemsPerPage in the query
        queryParams.push(`page=${page}`);
        if (itemsPerPage) queryParams.push(`itemsPerPage=${itemsPerPage}`);
        // Adjust this line as needed
        else {
          queryParams.push(`itemsPerPage=18`);
        }

        if (order) {
          queryParams.push(`order=${order}`);
        } else if (!usingFilter) {
          queryParams.push(`order=average_rating`);
        }

        if (reverse !== null) {
          queryParams.push(`reverse=${reverse}`);
        }

        let url = `${baseUrl}?${queryParams.join("&")}`;

        const response = await axios.get(url);
        const results = response.data.results;
        const count = response.data.count;
        setGames(results || []);
        //console.log(response.data);
        //console.log(count, itemsPerPage, Math.ceil(count / itemsPerPage));
        const newTotal = Math.max(1, Math.ceil(count / (itemsPerPage ?? 18)));
        setTotalPages(newTotal);
      } catch (err) {
        console.error(err);
        setPage(1);
      } finally {
        setIsLoading(false);
      }
    };

    if (itemsPerPage !== null) fetchGames();
  }, [filter, filterSet, page, order, reverse, itemsPerPage]); // Added itemsPerPage as a dependency

  useEffect(() => {
    // Check if the games array is not empty to ensure content is loaded
    if (games.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [page, games]);

  useEffect(() => {
    //console.log("LOCATION CHANGED", location.search, urlToFilterSet(decodeURIComponent(location.search)));
    const query = new URLSearchParams(location.search);
    if (query.get("filter")) setFilter(decodeURIComponent(query.get("filter")));
    // NOTE this is just the genre name or other filter types rn, should probably rename this later
    else {
      setFilter(null);
    }
    setPage(
      query.get("page") && !isNaN(query.get("page"))
        ? parseInt(query.get("page"))
        : 1
    );
    setFilterSet(urlToFilterSet(decodeURIComponent(location.search)));
    if (query.get("order")) {
      setOrder(query.get("order"));
    }
    if (query.get("reverse") !== null) {
      setReverse(query.get("reverse"));
    }
  }, [location]);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      updateFrontendUrl({ pg: newPage });
    }
  };

  const filterByGenreTagClick = (genre_name) => {
    updateFrontendUrl({ pg: 1, fs: { genres__name: genre_name } });
  };

  return (
    <div style={{ margin: `20px 300px ${filter ? "20px" : "0"} 300px` }}>
      {" "}
      {/* Container to align header and grid */}
      {!filter && !usingFilterSet && (
        <div>
          <h1 className="headers">BROWSE GAMES</h1>
        </div>
      )}
      {usingFilterSet &&
        filterSet &&
        filterSet.hasOwnProperty("title__icontains") &&
        filterSet["title__icontains"] !== "" && (
          <h1 className="headers">
            Showing results for: {filterSet["title__icontains"]}
          </h1>
        )}
      {usingFilterSet &&
        filterSet &&
        filterSet.hasOwnProperty("genres__name") &&
        filterSet["genres__name"] !== "" && (
          <h1 className="headers">
            Filtering by genre: {filterSet["genres__name"]}
          </h1>
        )}
      <div style={{ marginBottom: "20px" }}>
        <GamesFilter
          filterHandler={filterHandler}
          orderHandler={orderHandler}
        />
      </div>
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(200px, 1fr))",
          gridGap: "20px",
        }}
      >
        {games.map((game) => (
          <div className="games-tags" key={game.id}>
            <React.Suspense
              key={"reactsus." + game.id}
              fallback={<div>Loading...</div>}
            >
              <GameCard
                id={game.id}
                title={game.title}
                imageUrl={game.thumbnail_url}
                slug={game.slug}
                key={"gamecard." + game.id}
                titleColor="#E6E6E6"
              />
            </React.Suspense>
            <div className="tag-button" key={game.id + ".tagbuttondiv"}>
              {game.genres.map((genre, index) => (
                <TagButton
                  key={`${game.id}-${index}`}
                  label={genre.name}
                  color="#737076"
                  textColor="#CACACA"
                  hoverColor="#9758d7"
                  margin="-40px 5px 50px 0"
                  hoverTextColor="#ffffff"
                  onClick={() => {
                    filterByGenreTagClick(genre.name);
                  }}
                />
              ))}
            </div>
          </div>
        ))}
        <div></div>
      </div>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(page - 1)}
          disabled={page === 1}
        >
          Previous
        </button>
        <span>
          Page {page}/{totalPages}
        </span>
        <button
          onClick={() => handlePageChange(page + 1)}
          disabled={page === totalPages || isFetching}
        >
          Next
        </button>
      </div>
      <div>
        {isLoading && (
          <div style={{ textAlign: "center", color: "#ececec" }}>
            Loading...
          </div>
        )}
      </div>
    </div>
  );
}

export default Browse;
