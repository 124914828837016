import React, { useState, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import "./Signup.css";
import { Navigate, Link } from "react-router-dom";
import { connect } from "react-redux";
import { signup } from "../actions/auth";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

const Signup = ({
  signup,
  signUpFailed,
  signUpFailedMessage,
  isAuthenticated,
}) => {
  const recaptcha = useRef();
  const [signing, setSigning] = useState(false);
  const [requestSent, setRequestSent] = useState(false);
  const [formData, setFormData] = useState({
    username: "",
    email: "",
    password: "",
    re_password: "",
  });

  const { username, email, password, re_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    const captchaValue = recaptcha.current.getValue()
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      const config = {
        headers: {
          'Content-Type': 'application/json'
        }
      };
      const body = JSON.stringify({ token: captchaValue });

      setSigning(true);
      axios.post(
        `${process.env.REACT_APP_API_URL}/verify-recaptcha/`,
        body,
        config
      ).then(async (response) => {
        if (response.data.success) {
          await signup(username, email, password, re_password);
          setRequestSent(true);
        } else {
          setRequestSent(false);
          alert('Recaptcha not verified');
        }
      }).catch((err) => {
        console.log(err);
        setRequestSent(false);
        alert('Recaptcha not verified');
      }).finally(() => {
        setSigning(false);
        if (recaptcha.current)
          recaptcha.current.reset();
      })
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className="signup-container">
      {" "}
      {/* This will be the flex container */}
      <div className="signup-form center-form">
        <h1 className="login-title">Sign Up</h1>
        {requestSent && signUpFailed && (
          <p style={{ color: "red" }}>{signUpFailedMessage}</p>
        )}
        {(!requestSent || signUpFailed || signUpFailed === null) && !signing ? (
          <div>
            <Form onSubmit={(e) => onSubmit(e)}>
              <Form.Group className="form-group" controlId="username">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  name="username"
                  placeholder="Enter username"
                  value={username}
                  onChange={(e) => onChange(e)}
                  className="input-field"
                  required
                />
              </Form.Group>
              <Form.Group className="form-group" controlId="email">
                <Form.Label>E-mail</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  placeholder="Enter e-mail"
                  value={email}
                  onChange={(e) => onChange(e)}
                  className="input-field"
                  required
                />
              </Form.Group>
              <Form.Group className="form-group" controlId="password">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => onChange(e)}
                  className="input-field"
                  required
                />
              </Form.Group>
              <Form.Group className="form-group" controlId="re_password">
                <Form.Label>Re-type Password</Form.Label>
                <Form.Control
                  type="password"
                  name="re_password"
                  placeholder="Re-type Password"
                  value={re_password}
                  onChange={(e) => onChange(e)}
                  className="input-field"
                  required
                />
              </Form.Group>
              <Button variant="primary" type="submit" className="submit-button">
                Submit
              </Button>
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
            </Form>
            <p className="mt-3">
              Already have an account?{" "}
              <Link to="/login" className="forgot-password-link">
                Log in
              </Link>
            </p>
          </div>
        ) : (signing ? (
          <div>Signing up...</div>
        ) : (
          <p>
            You have successfully signed up! An e-mail was sent to {email}{" "}
            containing a link to activate your account.
          </p>
        )
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  signUpFailed: state.auth.signUpFailed,
  signUpFailedMessage: state.auth.signUpFailedMessage,
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { signup })(Signup);
