import React from "react";
import { useNavigate } from "react-router-dom";
import "./ProfileCard.css";

const ProfileCard = ({ imageUrl, username, usernameColor }) => {
  const navigate = useNavigate();

  const navigateToProfile = () => {
    navigate(`/profile/${username}`);
  };

  return (
    <div className="profile-card">
      {/* Wrap only the image and username with the onClick handler */}
      <div className="clickable-area" onClick={navigateToProfile}>
        <img
          src={imageUrl ? imageUrl : `/images/default_pfp.png`}
          alt={username}
          className="profile-image"
        />
        <p className="username" style={{ color: usernameColor }}>
          {username}
        </p>
      </div>
    </div>
  );
};

export default ProfileCard;
