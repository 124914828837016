import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SearchResult.css";
import { Link } from "react-router-dom";

const SearchResult = ({ game = null, user = null }) => {
  return game ? (
    <Link className="search-result-item" to={`/game/${game.id}/${game.slug}`}>
      <img
        src={game.thumbnail_url}
        alt={game.title}
        className="game-thumbnail"
      />
      {game.title}
    </Link>
  ) : (
    user && (
      <Link className="search-result-item" to={`/profile/${user.username}`}>
        <img
          src={user.profile_picture || "/images/default_pfp.png"}
          alt={user.username}
          className="user-profile-picture"
        />
        {user.username}
      </Link>
    )
  );
};

export default SearchResult;
