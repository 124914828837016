import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./hocs/Layout";
import Home from "./screens/Home";
import Forum from "./screens/Forum";
import PrivateRoute from "./routes/PrivateRoute";
import Browse from "./screens/Browse";
import Activate from "./screens/Activate";
import Login from "./screens/Login";
import ResetPassword from "./screens/ResetPassword";
import ResetPasswordConfirm from "./screens/ResetPasswordConfirm";
import Signup from "./screens/Signup";
import GameDetailView from "./screens/GameDetailView"; // Import GameDetails
import { Provider } from "react-redux";
import Story from "./screens/Story";
import ThreadDetailView from "./screens/ThreadDetailView";
import store from "./store";
import Profile from "./screens/Profile";
import Footer from "./components/Footer";
import ThreadDetailViewl from "./screens/ThreadDetailView";

function App() {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/browse" element={<Browse />} />
            <Route path="/profile/:id" element={<Profile />} />
            <Route path="/login" element={<Login />} />
            <Route path="/activate/:uid/:token" element={<Activate />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/story" element={<Story />} />
            <Route path="/forum" element={<Forum />} />
            <Route path="/forum/:id" element={<ThreadDetailView />} />{" "}
            <Route
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route path="/signup" element={<Signup />} />
            <Route path="/game/:id/:slug" element={<GameDetailView />} />{" "}
          </Routes>
        </Layout>
      </BrowserRouter>
      <Footer />
    </Provider>
  );
}

export default App;
