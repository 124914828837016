import React, { useState, useEffect } from "react";
import axios from "axios";
import "./FavouriteButton.css"; // Make sure your CSS is set up to position the notification as desired
import { faIcons } from "@fortawesome/free-solid-svg-icons";
import { FaHeartBroken, FaHeart } from "react-icons/fa";
export const FavouriteButton = ({ gameId, user, initialFavourite, access, checkAuthenticated }) => {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");

  const [favourite, setFavourite] = useState(initialFavourite);
  useEffect(() => {
    setFavourite(initialFavourite);
  }, [initialFavourite]);
  const addToFavourite = async () => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        "Authorization": `JWT ${access}`
      },
    };
    const body = JSON.stringify({ game: gameId, user: user });

    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/rating/add_favourite/`,
        body,
        config
      );
      console.log(result)
      setNotificationMessage("Game added to favourites!");
      if (!favourite) setShowNotification(true);
      setFavourite(!favourite);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000); // Hide the notification after 3 seconds
    } catch (error) {
      const res = error.response;
      console.error("Failed to add to favourites:", error);
      
      var message = "Failed to add game to favourites.";
      if (Object.keys(res.data).length !== 0) {
        if (res.data.hasOwnProperty("code") && res.data.code === 'token_not_valid') {
          checkAuthenticated();
          message = "Your login has expired. Please try again.";
        }
      }

      setNotificationMessage(message);
      setShowNotification(true);
      setFavourite(!favourite);
      setTimeout(() => {
        setShowNotification(false);
      }, 3000); // Hide the notification after 3 seconds
    }
  };

  return (
    <>
      <div
        data-v-0228dea0=""
        data-v-5776f768=""
        className="favourite"
        onClick={addToFavourite}
      >
        {favourite ? <FaHeartBroken /> : <FaHeart />}
      </div>

      {showNotification && (
        <div className="notification">{notificationMessage}</div>
      )}
    </>
  );
};
