import React, { useState } from "react";
import ProfileCard from "./ProfileCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import "./Review.css";

const Review = ({ imageUrl, username, reviewText, score }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const maxLength = 400; // Set the maximum length of the text that should be displayed initially

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  console.log("inside review", score);

  return (
    <div className="review">
      <ProfileCard
        imageUrl={imageUrl}
        username={username}
        usernameColor={"#ececec"}
      />
      {score !== null && <div className="score">Score: {score}</div>}
      <div className="review-text">
        {isExpanded || reviewText.length <= maxLength
          ? reviewText
          : `${reviewText.substring(0, maxLength)}...`}
        {reviewText.length > maxLength && (
          <div className="show-more-button-container">
            <button className="show-more-button" onClick={toggleExpanded}>
              <FontAwesomeIcon
                icon={isExpanded ? faChevronUp : faChevronDown}
              />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Review;
