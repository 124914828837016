import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { connect } from "react-redux";
import { useParams, Link, Navigate } from "react-router-dom";
import { reset_password_confirm } from "../actions/auth";

const ResetPasswordConfirm = ({ isAuthenticated }) => {
  const { uid, token } = useParams();

  const [requestSuccess, setRequestSuccess] = useState(false);
  const [requestFailed, setRequestFailed] = useState(false);
  const [requestFailureMessage, setRequestFailureMessage] = useState("");
  const [formData, setFormData] = useState({
    new_password: "",
    re_new_password: "",
  });

  const { new_password, re_new_password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = async (e) => {
    e.preventDefault();

    const res = await reset_password_confirm(
      uid,
      token,
      new_password,
      re_new_password
    );
    if (res.success) {
      setRequestSuccess(true);
      setRequestFailed(false);
      setRequestFailureMessage("");
    } else {
      setRequestFailed(true);
      setRequestFailureMessage(res.message);
      setRequestSuccess(false);
    }
  };

  if (isAuthenticated) {
    return <Navigate to="/" />;
  }

  return (
    <div className="signup-container">
      {requestFailed && <p className="signup-error">{requestFailureMessage}</p>}
      {!requestSuccess ? (
        <Form onSubmit={(e) => onSubmit(e)} className="signup-form">
          <h1>Reset Password</h1>
          <Form.Group className="form-group" controlId="password">
            <Form.Label>Enter New Password</Form.Label>
            <Form.Control
              type="password"
              name="new_password"
              placeholder="Enter your new password"
              value={new_password}
              onChange={(e) => onChange(e)}
              required
              className="form-control"
            />
          </Form.Group>

          <Form.Group className="form-group" controlId="re_password">
            <Form.Label>Confirm New Password</Form.Label>
            <Form.Control
              type="password"
              name="re_new_password"
              placeholder="Re-type your new password"
              value={re_new_password}
              onChange={(e) => onChange(e)}
              required
              className="form-control"
            />
          </Form.Group>
          <Button variant="primary" type="submit" className="submit-button">
            Submit
          </Button>
        </Form>
      ) : (
        <p className="signup-footer">
          Your password reset was successful!{" "}
          <Link to="/login" className="forgot-password-link">
            Log in
          </Link>
          .
        </p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, null)(ResetPasswordConfirm);
