import React, { useEffect } from "react";
import AppNavbar from "../components/AppNavbar";
import { connect } from "react-redux";
import { checkAuthenticated } from "../actions/auth";

const Layout = (props) => {
  useEffect(() => {
    props.checkAuthenticated();
  }, []);

  return (
    <div style={{ backgroundColor: "#333135" }}>
      <AppNavbar />
      {props.children}
    </div>
  );
};

export default connect(null, { checkAuthenticated })(Layout);
