import React, { useEffect, useState } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Modal, Form, Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { STATUS_CHOICES_DISPLAY } from "../util/util";
import axios from 'axios';
import "./GamesFilter.css";

function GamesFilter({ filterHandler, orderHandler, currentSortBy = "Top Rated", isUserList = false }) {
  const [show, setShow] = useState(false);
  const baseFilters = {
    title__icontains: "",
    publishers__name: "",
    developers__name: "",
    platforms__name: "",
    genres__name: "",
    release_date__lte: "",
    release_date__gte: "",
    every: true, // whether to filter with AND (every) or OR (some)
  };
  const initialFilters = isUserList
    ? { ...baseFilters, rating__lte: "", rating__gte: "", game_status: "" }
    : baseFilters;
  const [filters, setFilters] = useState(initialFilters);
  const [searchValue, setSearchValue] = useState("");
  const [allGenres, setAllGenres] = useState([]);
  const [sortBy, setSortBy] = useState(currentSortBy);

  useEffect(() => {
    const fetchGenres = async () => {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/genre/`);
      if (response.data) {
        setAllGenres(response.data.map(genre => genre.name));
      }
    };

    fetchGenres();
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const handleSearch = (e) => {
    const value = e.target.value;
    setSearchValue(value);
    setFilters({ ...filters, ["title__icontains"]: value });
    if (!isUserList) { // Later, if we add more pages, do some kind of switch case with an enum or list of constants
      filterHandler({ ...filters, ["title__icontains"]: value }); // if not user page, just set modify title
    } else {
      let newFilters;
      if (value === null || value === "") {
        newFilters = initialFilters;
      } else {
        const updatedFilters = {};
        for (const filterKey in filters) {
          if (filterKey.indexOf("lte") === -1 && filterKey.indexOf("gte") === -1) {
            if (filterKey === 'game_status') {
              updatedFilters['game_status__icontains'] = value;
            } else {
              updatedFilters[filterKey] = value;
            }
          }
        }
        updatedFilters.every = false;
        newFilters = updatedFilters;
      }
      filterHandler(newFilters);
    }
  };

  const onChange = (e) => {
    const { name: filterName, value } = e.target;
    let finalValue;

    if (filterName.indexOf("rating") !== -1 && !isNaN(value) && value !== "") {
      finalValue = parseInt(value);
    } else {
      finalValue = value;
    }

    setFilters({
      ...filters,
      [filterName]: finalValue,
    });
  };

  const onSubmit = (e) => {
    filterHandler(filters);
    setSearchValue(filters["title__icontains"]);
    setShow(false);
  };

  const onCancel = (e) => {
    setFilters(initialFilters);
    filterHandler(initialFilters);
    setSearchValue("");
    setShow(false);
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      onSubmit(e);
    }
  };

  const handleSortBy = (order, orderName, reverse=false) => {
    setSortBy(orderName);
    orderHandler(order, reverse);
  };

  return (
    <Container>
      <Row className="align-items-center">
        <Col className="d-flex justify-content-end">
          {
            isUserList && (
              <Form.Control
                className="search-form mr-3"
                type="text"
                name="search"
                onChange={handleSearch}
                value={searchValue}
                placeholder={
                  isUserList ? "Search by title, genre, etc..." : "Search by title"
                }
              />
            )
          }
          {
            !isUserList && (
              <Dropdown>
                <Dropdown.Toggle variant="primary" id="dropdown-menu-align-responsive-1">
                  Sort by: { sortBy }
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item onClick={() => handleSortBy('average_rating', 'Top Rated')}>Top Rated</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortBy('average_rating', 'Worst Rated', true)}>Worst Rated</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortBy('title', 'Title (A-Z)')}>Title (A-Z)</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortBy('title', 'Title (Z-A)', true)}>Title (Z-A)</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortBy('popularity', 'Most Popular')}>Most Popular</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortBy('popularity', 'Least Popular', true)}>Least Popular</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortBy('release_date', 'Newest', true)}>Newest</Dropdown.Item>
                  <Dropdown.Item onClick={() => handleSortBy('release_date', 'Oldest')}>Oldest</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            )
          }
          &nbsp;
          <Button
            onClick={handleShow}
            variant="primary"
          >
            <FontAwesomeIcon icon={faFilter} /> Filter
          </Button>{" "}
        </Col>
      </Row>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Filter Games</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Form.Group className="mb-3" controlId="title">
                <Form.Label>Title</Form.Label>
                <Form.Control
                  type="text"
                  name="title__icontains"
                  placeholder="e.g. Steins;gate, Call of Duty..."
                  value={filters.title__icontains}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                  autoFocus
                />
              </Form.Group>
            </Row>
            {isUserList && (
              <>
                <Row>
                  <Form.Group className="mb-3" controlId="game_status">
                    <Form.Label>Game Status</Form.Label>
                    <Form.Select
                      name="game_status"
                      value={filters.game_status}
                      onChange={onChange}
                      onKeyDown={handleKeyDown}
                    >
                      <option value="">--</option>
                      {STATUS_CHOICES_DISPLAY.map((choice) => (
                        <option key={"game_status." + choice}>{choice}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="rating.min">
                      <Form.Label>Min Score</Form.Label>
                      <Form.Control
                        type="number"
                        name="rating__gte"
                        min="1"
                        max="10"
                        placeholder="Enter min score"
                        value={filters.rating__gte}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-3" controlId="rating.max">
                      <Form.Label>Max Score</Form.Label>
                      <Form.Control
                        type="number"
                        name="rating__lte"
                        min="1"
                        max="10"
                        placeholder="Enter max score"
                        value={filters.rating__lte}
                        onChange={onChange}
                        onKeyDown={handleKeyDown}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </>
            )}

            <Row>
              <Form.Group className="mb-3" controlId="publishers">
                <Form.Label>Publisher</Form.Label>
                <Form.Control
                  type="text"
                  name="publishers__name"
                  placeholder="e.g. 5pb., Spike Chunsoft..."
                  value={filters.publishers__name}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="developers">
                <Form.Label>Developer</Form.Label>
                <Form.Control
                  type="text"
                  name="developers__name"
                  placeholder="e.g. 5pb., Riot..."
                  value={filters.developers__name}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="platforms">
                <Form.Label>Platform</Form.Label>
                <Form.Control
                  type="text"
                  name="platforms__name"
                  placeholder="e.g. Playstation 5, Nintendo Switch..."
                  value={filters.platforms__name}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-3" controlId="genres">
                <Form.Label>Genre</Form.Label>
                <Form.Select
                  name="genres__name"
                  value={filters.genres__name}
                  onChange={onChange}
                  onKeyDown={handleKeyDown}
                >
                  <option value="">--</option>
                  {allGenres.map((choice) => (
                    <option key={"genres." + choice}>{choice}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="release_date.min">
                  <Form.Label>Released After</Form.Label>
                  <Form.Control
                    type="date"
                    name="release_date__gte"
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    value={filters.release_date__gte}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="release_date.max">
                  <Form.Label>Released Before</Form.Label>
                  <Form.Control
                    type="date"
                    name="release_date__lte"
                    onChange={onChange}
                    onKeyDown={handleKeyDown}
                    value={filters.release_date__lte}
                  />
                </Form.Group>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={onCancel}>
            Remove All Filters
          </Button>
          <Button variant="primary" onClick={onSubmit}>
            Filter
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
}

export default GamesFilter;
