import React from "react";
import { Link } from "react-router-dom";
import ProfileCard from "../components/ProfileCard";
const Story = () => {
  return (
    <div style={{ minHeight: "100vh", color: "white", textDecoration: "none" }}>
      Developers:
      <br />
      <Link
        to={`/profile/kennel_master3`}
        style={{ textDecoration: "none", color: "white" }}
      >
        Kennel_master3
      </Link>
      <br />
      <Link
        to={`/profile/sean`}
        style={{ textDecoration: "none", color: "white" }}
      >
        Sean
      </Link>
      <br />
      <Link
        to={`/profile/smallcat`}
        style={{ textDecoration: "none", color: "white" }}
      >
        smallcat
      </Link>
    </div>
  );
};

export default Story;
