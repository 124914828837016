import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";

const ArrowLink = ({ link, direction, onClick = null }) => {
  // Add the link prop
  const [hover, setHover] = useState(false);

  return (
    <Link
      to={link} // Use the link prop
      style={{ marginRight: "10px" }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick ? () => onClick() : ""}
    >
      <FontAwesomeIcon
        icon={direction === "right" ? faArrowRightLong : faArrowLeftLong}
        size="2x"
        style={{ color: hover ? "#9758d7" : "#565656" }}
      />{" "}
    </Link>
  );
};

export default ArrowLink;
