import { applyMiddleware } from 'redux';
import { configureStore, Tuple } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import rootReducer from './reducers';
import monitorReducersEnhancer from './enhancers/monitorReducers';
import loggerMiddleware from './middleware/logger';

const store = configureStore({
    reducer: rootReducer,
    middleware: () => new Tuple(thunk, loggerMiddleware),
    enhancers: (getDefaultEnhancers) => getDefaultEnhancers().prepend(monitorReducersEnhancer),
});

export default store;

