import React, { useState } from "react";
import axios from "axios";

const ReviewForm = ({ gameId, userId, existingReview, onSubmit }) => {
  const [text, setText] = useState(existingReview?.text || "");
  const [error, setError] = useState("");

  const handleInputChange = (e) => {
    setText(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");

    const reviewData = {
      game: gameId,
      user: userId,
      text: text,
    };

    try {
      if (text.trim() === "" && existingReview) {
        // Delete existing review
        await axios.delete(
          `${process.env.REACT_APP_API_URL}/api/reviews/${existingReview.id}/`
        );
        alert("Review deleted successfully");
      } else if (existingReview) {
        // Update existing review
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/reviews/${existingReview.id}/`,
          reviewData
        );
        alert("Review updated successfully");
      } else {
        // Post new review
        await axios.post(
          `${process.env.REACT_APP_API_URL}/api/reviews/`,
          reviewData
        );
        alert("Review submitted successfully");
      }
      onSubmit();
      // Optionally, reset the form or redirect the user
    } catch (error) {
      setError("Failed to submit review. Please try again.");
      console.error("There was an error submitting the review:", error);
    }
  };

  return (
    <div>
      <h2>{existingReview ? "Update Your Review" : "Write a Review"}</h2>
      {error && <p className="error">{error}</p>}
      <form onSubmit={handleSubmit}>
        <textarea
          value={text}
          onChange={handleInputChange}
          placeholder="Share your thoughts about the game..."
          style={{ width: "100%", height: "100px" }}
        />
        <button type="submit" style={{ marginTop: "10px" }}>
          {existingReview ? "Update Review" : "Post Review"}
        </button>
      </form>
    </div>
  );
};

export default ReviewForm;
