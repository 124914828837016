import {
    LOGIN_SUCCESS,
    LOGIN_SUCCESS_KEEP_SIGNED_IN,
    REFRESH_SUCCESS,
    LOGIN_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    SIGNUP_SUCCESS,
    SIGNUP_FAIL,
    ACTIVATION_SUCCESS,
    ACTIVATION_FAIL,
    LOGOUT,
} from '../actions/types';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    loginFailed: null, // set loginFailed and loginFailed to null at the start of every page load (AUTHENTICATED_SUCCES/FAIL), only set these during LOGIN_FAILED
    loginFailedMessage: null,
    signUpFailed: null,
    signUpFailedMessage: null,
};

export default function(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true,
                loginFailed: null,
                loginFailedMessage: null,
                signUpFailed: null,
                signUpFailedMessage: null,
            }
        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
            }
        case REFRESH_SUCCESS:
        case LOGIN_SUCCESS_KEEP_SIGNED_IN:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh,
            }
        case SIGNUP_SUCCESS:
            return {
                ...state,
                signUpFailed: false,
                isAuthenticated: false,
            }
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
            }
        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                loginFailed: null,
                loginFailedMessage: null,
                signUpFailed: null,
                signUpFailedMessage: null,
            }
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null,
            }
        case LOGIN_FAIL:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                loginFailed: true,
                loginFailedMessage: payload.message,
                isAuthenticated: false,
                user: null,
            }
        case SIGNUP_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                signUpFailed: true,
                signUpFailedMessage: payload.message,
            }
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
            }
        case ACTIVATION_SUCCESS:
        case ACTIVATION_FAIL:
            return {
                ...state
            }
        default:
            return state;
    }
};