import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import "./GameTableHeader.css";

function GameTableHeader({ header_names, fields, orderHandler, unorderable=[], reversedFields=['rating'] }) {
  const [orderField, setOrderField] = useState(null);
  const [isReverse, setReverse] = useState(false);

  const onOrder = ( value ) => {
    if (value == orderField) {
      setReverse(!isReverse);
    } else {
      setOrderField(value);
      setReverse(reversedFields.includes(value)); // initial sort order is reverse if it's a reversed field, otherwise it's non-reversed
    }
  };

  useEffect(() => {
    orderHandler(orderField, isReverse);
  }, [orderField, isReverse])

  const removeOrder = () => setOrderField(null);

  return (
    <thead className='thead-dark'>
        <tr>
            {
                header_names.map( (field_name, i) => (
                    <th key = { field_name + ".header" }>
                      <Link className={ `order_link${unorderable.includes(fields[i]) ? ' unorderable' : ''}` }
                        onClick = {
                          unorderable.includes(fields[i]) ? null : () => onOrder(fields[i])
                        }>
                          { field_name }
                      </Link>
                      {
                        (fields[i] == orderField) && (
                          <Link className='order_link' onClick={() => removeOrder()}>
                            { isReverse == reversedFields.includes(fields[i]) ? '▾' : '▴' }
                          </Link>
                        )
                      }
                    </th>
                ))
            }
        </tr>
    </thead>
  );
}

export default GameTableHeader;
