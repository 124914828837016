import React, { Fragment } from "react";
import { Container, Navbar, Nav, NavDropdown, Form } from "react-bootstrap"; // import Form
import "bootstrap/dist/css/bootstrap.min.css";
import "./SearchBar.css";
import Image from "react-bootstrap/Image";
import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { InputGroup, FormControl } from "react-bootstrap"; // import InputGroup and FormControl
import { FaSearch, FaUser, FaCog, FaBell, FaSignOutAlt } from "react-icons/fa"; // import icons
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import axios from "axios";
import { useDebounce } from "../util/customhooks";

const SearchBar = ({ setGameResults, setUserResults }) => {
  let location = useLocation();
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (!location.pathname.includes("/browse")) {
      setSearchTerm("");
      setUserResults([]);
      setGameResults([]);
    }
  }, [location]);

  const handleSearch = (e) => {
    // Check if an event object was passed
    if (e) {
      e.preventDefault();
    }
    console.log(searchTerm);
    navigate(`/browse?title__icontains=${encodeURIComponent(searchTerm)}`);
  };

  const sendQuery = useDebounce(() => {
    if (!searchTerm || searchTerm == "") {
      setGameResults([]);
      setUserResults([]);
    } else {
      const gameUrl = `${
        process.env.REACT_APP_API_URL
      }/api/games/?title__icontains=${encodeURIComponent(
        searchTerm
      )}&order=title&itemsPerPage=5`;
      axios.get(gameUrl).then((res) => {
        if (res.data && res.data.results) {
          console.log("game results", res.data.results);
          setGameResults(res.data.results);
        } else {
          setGameResults([]);
        }
      });
      const userUrl = `${
        process.env.REACT_APP_API_URL
      }/api/users/?search=${encodeURIComponent(searchTerm)}&itemsPerPage=5`;
      axios.get(userUrl).then((res) => {
        if (res.data && res.data.results) {
          console.log("user results", res.data.results);
          setUserResults(res.data.results);
        } else {
          setUserResults([]);
        }
      });
    }
  });

  const onChange = (e) => {
    setSearchTerm(e.target.value);
    sendQuery();
  };

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSearch(); // Call handleSearch on form submission
      }}
      style={{ marginRight: "auto" }}
    >
      <InputGroup>
        <InputGroup.Text
          style={{
            border: "none",
            backgroundColor: "transparent",
            color: "#959595",
          }}
        >
          <FaSearch />
        </InputGroup.Text>
        <FormControl
          type="text"
          placeholder="Search"
          className="search-bar"
          style={{
            borderWidth: "2px",
            borderStyle: "solid",
            borderRadius: "50px",
            backgroundColor: "transparent",
            borderColor: "#ececec",
            color: "#ffffff",
            outline: "0 0 0 0.2rem rgba(255, 255, 255, 0.25)",
            boxShadow: "none",
          }}
          aria-label="Search"
          value={searchTerm} // Controlled component
          onChange={onChange} // Update searchTerm on change
        />
      </InputGroup>
    </Form>
  );
};

export default SearchBar;
