import React, { useState } from "react";
import "./TagButton.css"; //put css in here later
const TagButton = (props) => {
  const { label, color, textColor, hoverColor, hoverTextColor, fontWeight, margin } = props;

  const [currentColor, setCurrentColor] = useState(color);
  const [currentTextColor, setCurrentTextColor] = useState(textColor);

  const buttonStyle = {
    display: "inline-flex", // changed to inline-flex
    justifyContent: "center", // center text horizontally
    alignItems: "center", // center text vertically
    padding: "5px 10px", // increased left and right padding
    borderRadius: "25px",
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: "12px",
    height: "30px",
    margin: margin,
    backgroundColor: currentColor,
    color: currentTextColor,
    fontWeight: fontWeight,
    cursor: "pointer"
  };

  return (
    <div
      
      style={buttonStyle}
      onMouseOver={() => {
        setCurrentColor(hoverColor);
        setCurrentTextColor(hoverTextColor);
      }}
      onMouseOut={() => {
        setCurrentColor(color);
        setCurrentTextColor(textColor);
      }}
      onClick={props.onClick}
    >
      {label}
    </div>
  );
};

export default TagButton;
