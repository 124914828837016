import React from "react";
import "./WelcomeBox.css";
import stonks from "../images/stonks.svg";
import palette from "../images/palette.svg";
import speech from "../images/speech.svg";
import phone from "../images/phone.svg";

const WelcomeBox = () => {
  return (
    <>
      <div data-v-3428d21c="" className="landing">
        <h1 data-v-3428d21c="" className="heading">
          <span data-v-3428d21c="">eList.gg: </span>
          The ultimate game tracker
        </h1>
        <h2 data-v-3428d21c="" className="sub-heading">
          Track, share, and discover your favorite games with eList.gg.
        </h2>
        <div data-v-3428d21c="" className="feature-cards">
          <div data-v-3428d21c="" className="feature-card">
            <div data-v-3428d21c="" className="icon">
              <img data-v-3428d21c="" src={stonks} />
            </div>
            <div data-v-3428d21c="" className="card-content">
              <h3 data-v-3428d21c="" className="title">
                Discover your favorites
              </h3>
              <div data-v-3428d21c="" className="description">
                What are your most played genres or highest rated games? Follow
                your gaming habits over time with in-depth statistics.
              </div>
            </div>
          </div>
          <div data-v-3428d21c="" className="feature-card">
            <div data-v-3428d21c="" className="icon">
              <img data-v-3428d21c="" src={phone} />
            </div>
            <div data-v-3428d21c="" className="card-content">
              <h3 data-v-3428d21c="" className="title">
                Access eList.gg anywhere
              </h3>
              <div data-v-3428d21c="" className="description">
                Keep track of your gaming progress on-the-go with eList.gg's
                mobile apps across iOS, Android, and more.
              </div>
            </div>
          </div>
          <div data-v-3428d21c="" className="feature-card">
            <div data-v-3428d21c="" className="icon">
              <img data-v-3428d21c="" src={speech} />
            </div>
            <div data-v-3428d21c="" className="card-content">
              <h3 data-v-3428d21c="" className="title">
                Join the gaming community
              </h3>
              <div data-v-3428d21c="" className="description">
                Share your gaming experiences with our thriving community, make
                friends, socialize, and receive game recommendations.
              </div>
            </div>
          </div>
          <div data-v-3428d21c="" className="feature-card">
            <div data-v-3428d21c="" className="icon">
              <img data-v-3428d21c="" src={palette} />
            </div>
            <div data-v-3428d21c="" className="card-content">
              <h3 data-v-3428d21c="" className="title">
                Customize your experience
              </h3>
              <div data-v-3428d21c="" className="description">
                Personalize your gaming experience with customizable features,
                themes, and much more! Also, we have a dark mode.
              </div>
            </div>
          </div>
        </div>
        <a data-v-3428d21c="" href="/signup" className="relative z-10 join-btn">
          <div data-v-3428d21c="" className="label">
            Join Now
          </div>
          <div data-v-3428d21c="" className="circle">
            <svg
              data-v-3428d21c=""
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="chevron-right"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 320 512"
              className="svg-inline--fa fa-chevron-right fa-w-10"
            >
              <path
                data-v-3428d21c=""
                fill="currentColor"
                d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                className=""
              ></path>
            </svg>
          </div>
        </a>
      </div>
    </>
  );
};

export default WelcomeBox;
