export function capitalizeFirstLetter(word) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function combineMessages(res) {
  var message = "";
  for (const key in res.data) {
    if (Object.hasOwnProperty.call(res.data, key)) {
      const rawMessage = res.data[key];
      const newMessage = Array.isArray(rawMessage)
        ? capitalizeFirstLetter(rawMessage.join(" "))
        : capitalizeFirstLetter(rawMessage.toString());
      if (message.includes(newMessage)) {
        // prevents duplicate messages
        continue;
      }
      if (message === "") {
        message = newMessage;
      } else {
        message = message + " " + newMessage;
      }
    }
  }
  return message;
}

export const STATUS_CHOICES_DISPLAY = [
  "Currently Playing",
  "Completed",
  "Dropped",
  "Want to Play",
];

export const STATUS_CHOICES = ["playing", "completed", "dropped", "want"];

function getValueFromProperty(obj, propertyName, arrayValueComparisonField) {
  try {
    if (propertyName === "game_status") {
      return STATUS_CHOICES_DISPLAY.indexOf(obj[propertyName]);
    } else if (propertyName === "release_date") {
      return new Date(obj[propertyName]);
    } else if (Array.isArray(obj[propertyName])) {
      if (obj[propertyName] && obj[propertyName].length > 0)
        return obj[propertyName][0][arrayValueComparisonField];
      else {
        return "";
      }
    } else {
      return obj[propertyName];
    }
  } catch (err) {
    console.log(err);
    return "";
  }
}

export function sortByProperty(
  array,
  propertyName,
  isReverse = false,
  arrayValueComparisonField = "name",
  defaultOrder = "game_status"
) {
  if (propertyName === null) {
    propertyName = defaultOrder;
    isReverse = false;
  }
  return array.sort((a, b) => {
    if (!a.hasOwnProperty(propertyName) || !b.hasOwnProperty(propertyName)) {
      return 0;
    }
    const aValue = getValueFromProperty(
      a,
      propertyName,
      arrayValueComparisonField
    );
    const bValue = getValueFromProperty(
      b,
      propertyName,
      arrayValueComparisonField
    );

    if (aValue < bValue) {
      return isReverse ? 1 : -1;
    }
    if (aValue > bValue) {
      return isReverse ? -1 : 1;
    }
    return 0;
  });
}

function handleComparison(itemValue, filterValue, comparisonType) {
  switch (comparisonType) {
    case "lte":
      return itemValue <= filterValue;
    case "gte":
      return itemValue >= filterValue;
    case "name":
    case "icontains":
    default:
      return (
        itemValue
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .toLowerCase()
          .indexOf(
            filterValue
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          ) !== -1
      );
  }
}

export function filterData(data, filters) {
  const filterByKey = (item) => (key) => {
    // checks if every field of item fulfills the filter requirement
    const filterValue = filters[key];
    const [itemKey, comparisonType] = key.split("__");
    const itemValue = item[itemKey];

    if (key === "every" || filterValue === null || filterValue === "") {
      return filters.every; // ignore cases with no filterValue and the every case (ignore is filters.every, true will skip every false will skip some)
    }

    // Check for icontains (insensitive string contains)
    if (typeof filterValue === "string" && typeof itemValue === "string") {
      return handleComparison(itemValue, filterValue, comparisonType);
    }

    // Check for numeric conditions (greater than, less than)
    if (typeof filterValue === "number" && typeof itemValue === "string") {
      if (!isNaN(itemValue)) {
        return handleComparison(
          parseInt(itemValue),
          filterValue,
          comparisonType
        );
      } else {
        console.log(
          `Error: filter ${key} : ${filterValue} is being compared to non-number ${itemValue}.`
        );
        return filters.every;
      }
    }
    if (typeof filterValue === "number" && typeof itemValue === "number") {
      return handleComparison(itemValue, filterValue, comparisonType);
    }

    if (Array.isArray(itemValue)) {
      // comparison type (genres__name's is name) should be included in the filter key

      return itemValue.some((obj) => {
        return (
          obj[comparisonType]
            .toLowerCase()
            .indexOf(filterValue.toLowerCase()) !== -1
        );
      });
    }

    return itemValue === filterValue;
  };

  return data.filter((item) => {
    return filters.every
      ? Object.keys(filters).every(filterByKey(item))
      : Object.keys(filters).some(filterByKey(item));
  });
}

export function filterSetToUrl(filterSet) {
  if (!filterSet) return null;

  const url_arr = Object.keys(filterSet)
    .filter(
      (key) =>
        key !== "every" && filterSet[key] !== null && filterSet[key] !== ""
    )
    .map((key) => `${key}=${encodeURIComponent(filterSet[key])}`);
  return url_arr.join("&");
}

const FILTER_FIELDS = [
  "title__icontains",
  "publishers__name",
  "developers__name",
  "platforms__name",
  "genres__name",
  "engine__name",
  "release_date__gte",
  "release_date__lte",
];

export function urlToFilterSet(url) {
  const filterUrl =
    url.indexOf("?") !== -1 ? url.slice(url.indexOf("?") + 1) : "";
  if (filterUrl && filterUrl !== "") {
    var newFilterSet = {};
    const url_comps = filterUrl.split("&");
    for (let c of url_comps) {
      const [name, value] = c.split("=");
      if (FILTER_FIELDS.includes(name)) {
        newFilterSet[name] = value;
      }
    }
    return newFilterSet;
  } else {
    return null;
  }
}
