import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./SearchResultsList.css";
import SearchResult from "./SearchResult";

const SearchResultsList = ({ gameResults, userResults, showResults }) => {
  return (
    !(
      (!gameResults || gameResults.length === 0) &&
      (!userResults || userResults.length === 0)
    ) &&
    showResults && (
      <div className="search-results">
        {gameResults && gameResults.length > 0 && (
          <div className="search-category">Games</div>
        )}
        {gameResults.map((game) => (
          <SearchResult game={game} key={game.id} />
        ))}
        {userResults && userResults.length > 0 && (
          <div className="search-category">Users</div>
        )}
        {userResults.map((user) => (
          <SearchResult user={user} key={user.id} />
        ))}
      </div>
    )
  );
};

export default SearchResultsList;
