import React, { useEffect, useState } from "react";
import axios from "axios";
import ArrowLink from "../components/ArrowLink";
import { useParams, Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Row, Col, Container, Form, Modal, Button } from "react-bootstrap";
import { STATUS_CHOICES_DISPLAY, STATUS_CHOICES, combineMessages } from "../util/util";
import { connect } from "react-redux";
import Review from "../components/Review"; // Adjust the import path as necessary
import ReviewForm from "../components/ReviewForm";
import "./GameDetailView.css";

import { FavouriteButton } from "../components/FavouriteButton";

import TagButton from "../components/TagButton";
import {
  FaSteam,
  FaRedditAlien,
  FaTwitter,
  FaFacebookF,
  FaInstagram,
  FaYoutube,
  FaTwitch,
  FaDiscord,
  FaWikipediaW,
  FaGlobe,
  FaLink,
  FaCheckCircle,
  FaGamepad,
  FaTimesCircle,
  FaClock,
} from "react-icons/fa";
import { checkAuthenticated } from "../actions/auth";
function GameDetails({ isAuthenticated, user, access, checkAuthenticated }) {
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [game, setGame] = useState(null);
  const [avgRating, setAvgRating] = useState(null);
  const [userFavourite, setUserFavourite] = useState(false);
  const [userStatus, setUserStatus] = useState("");
  const [userScore, setUserScore] = useState("");
  const [unloadedMsg, setUnloadedMsg] = useState("Loading...");
  const { id: game_id } = useParams(); // Assuming you're using 'id' to fetch the game details
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [existingReview, setExistingReview] = useState(null);
  const [allExistingReviews, setAllExistingReviews] = useState([]);
  const [showAllReviews, setShowAllReviews] = useState(false);

  const handleReviewModalShow = () => setShowReviewModal(true);
  const handleReviewModalClose = () => setShowReviewModal(false);
  const toggleShowAllReviews = () => {
    setShowAllReviews(!showAllReviews);
  };
  // Assuming you're fetching the game object as before
  const numberOfWebsites = game?.websites?.length || 0;
  const baseHeightPerLink = 30;
  const paddingOrMarginTotal = 20;
  const totalHeight =
    numberOfWebsites * baseHeightPerLink + paddingOrMarginTotal + 440; //440 for image height
  const reviewsToShow = showAllReviews
    ? allExistingReviews
    : allExistingReviews.slice(0, 3);

  console.log("numberOfWebsites", numberOfWebsites);
  console.log("totalHeight", totalHeight);

  const handleReviewSubmit = () => {
    handleReviewModalClose();
    fetchExistingReview();
    fetchAllExistingReviews();
  };

  const navigate = useNavigate();
  const getWebsiteIcon = (category_name) => {
    const icons = {
      Steam: <FaSteam />,
      Reddit: <FaRedditAlien />,
      Twitter: <FaTwitter />,
      Facebook: <FaFacebookF />,
      Instagram: <FaInstagram />,
      YouTube: <FaYoutube />,
      Twitch: <FaTwitch />,
      Discord: <FaDiscord />,
      Wikipedia: <FaWikipediaW />,
      Official: <FaGlobe />,

      // Add more mappings as needed
    };
    return icons[category_name] || <FaLink />; // Default to null if no icon is found
  };

  const getStatusAndRating = async () => {
    if (isAuthenticated && user) {
      try {
        const status_res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/game_status/?user=${user.user.id}&game=${game_id}`
        );
        if (status_res.data.results && status_res.data.results.length > 0) {
          setUserStatus(status_res.data.results[0].status);
          setUserFavourite(status_res.data.results[0].favourite);
        }
        const rating_res = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/rating/?user=${user.user.id}&game=${game_id}`
        );
        if (rating_res.data.results && rating_res.data.results.length > 0)
          setUserScore(rating_res.data.results[0].score);
      } catch (err) {
        console.log(err);
      }
    }
  };

  const fetchExistingReview = async () => {
    if (!isAuthenticated || !user) {
      setExistingReview(null);
      return;
    }
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reviews/get_by_user_and_game/`,
        {
          params: {
            user: user.user.id,
            game: game_id,
          },
        }
      );
      if (response.data) {
        // Assuming the API now returns a single review or null
        setExistingReview(response.data); // Set the review found
      } else {
        setExistingReview(null); // No review found
      }
    } catch (error) {
      console.error("Failed to fetch existing review:", error);
      setExistingReview(null);
    }
  };

  useEffect(() => {
    fetchExistingReview();
  }, [isAuthenticated, user, game_id]);

  const fetchAllExistingReviews = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/reviews/get_by_game/`,
        {
          params: {
            game: game_id,
          },
        }
      );
      if (response.data.length > 0) {
        // Assuming the API now returns a single review or null
        console.log("hi this is all reviews", response.data);
        setAllExistingReviews(response.data); // Set the review found
      } else {
        setAllExistingReviews([]); // No review found
      }
    } catch (error) {
      console.error("Failed to fetch existing review:", error);
      setAllExistingReviews([]);
    }
  };

  useEffect(() => {
    fetchAllExistingReviews();
  }, [isAuthenticated, game_id]);

  const onChangeStatus = async (e) => {
    const new_status = e.target.value;
    if (isAuthenticated && user) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
          },
        };
        if (new_status === "") {
          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/game_status/remove_game_status/?user=${user.user.id}&game=${game_id}`,
            config
          );
          setUserStatus("");
          if (userScore !== "")
            onChangeScore({ target: { value: "" } });
        } else {
          const body = JSON.stringify({
            game: game_id,
            user: user.user.id,
            status: new_status,
          });
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/game_status/add_game_status/`,
            body,
            config
          );
          setUserStatus(response.data.status);
        }
      } catch (err) {
        console.log(err);
        handleErrorResDisplay(err, "There was a problem with adjusting your status!");
      }
    }
  };

  const handleErrorResDisplay = (err, defaultMsg) => {
    const res = err.response;

    var message = defaultMsg;
    if (Object.keys(res.data).length !== 0) {
      message = combineMessages(res);
      if (res.data.hasOwnProperty("code") && res.data.code === 'token_not_valid') {
        checkAuthenticated();
        message = "Your login has expired. Please try again.";
      }
    }

    setShowNotification(true);
    setNotificationMessage(message);
    setTimeout(() => {
      setShowNotification(false);
    }, 3000);
  }

  const onChangeScore = async (e) => {
    const new_score = e.target.value;
    if (isAuthenticated && user) {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${access}`,
          },
        };

        if (new_score === "") {
          // removing score
          const response = await axios.delete(
            `${process.env.REACT_APP_API_URL}/api/rating/remove_rating/?user=${user.user.id}&game=${game_id}`,
            config
          );
          setUserScore("");
        } else {
          const body = JSON.stringify({
            game: game_id,
            user: user.user.id,
            score: new_score,
          });
          const response = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/rating/add_rating/`,
            body,
            config
          );
          setUserScore(response.data.score);
          if (userStatus === "") {
            onChangeStatus({ target: { value: "playing" } });
          }
        }
        fetchAvgRating();
      } catch (err) {
        console.log(err);
        handleErrorResDisplay(err, "There was a problem with adjusting your score!");
      }
    }
  };

  const fetchAvgRating = async () => {
    const rating_response = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/games/average_rating/?id=${game_id}`
    );
    setAvgRating(
      game_id in rating_response.data
        ? Math.round(parseFloat(rating_response.data[game_id]) * 10) / 10
        : null
    );
  };

  const genreOnClick = (genreName) => {
    navigate(`/browse?genres__name=${encodeURIComponent(genreName)}`);
  };
  useEffect(() => {
    const fetchGame = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/games/${game_id}`
        );
        setGame(response.data);
      } catch (err) {
        setUnloadedMsg(
          `Game with id ${game_id} not found in our database! Sorry :(`
        );
      }
    };

    fetchAvgRating();
    getStatusAndRating();
    fetchGame();
  }, [game_id]);

  useEffect(() => {
    getStatusAndRating();
  }, [isAuthenticated, user]);

  if (!game) {
    return <div>{unloadedMsg}</div>;
  }

  return (
    <Container style={{ minHeight: "100vh" }}>
      <Row style={{ marginBottom: "10px" }}>
        <div style={{ display: "inline-block" }}>
          <ArrowLink
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        {/* <h1>{game.title}</h1> */}{" "}
      </Row>
      <Row>
        <Col
          xs={4}
          sm={4}
          md={3}
          style={{
            backgroundColor: "white",
            padding: "10px",
            borderRadius: "25px",
            boxShadow:
              "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
            marginRight: "40px",
            marginBottom: "30px",
            maxHeight: `${totalHeight}px`, // Use the calculated height// Use the calculated height
          }}
        >
          <Row>
            {game.thumbnail_url && (
              <img
                src={game.thumbnail_url.replace("t_thumb", "t_cover_big_2x")}
                alt={`${game.title} cover`}
                style={{ borderRadius: " 25px", marginBottom: "20px" }}
              />
            )}
          </Row>

          <Row>
            {game.websites &&
              game.websites.map((website, index) => (
                <a
                  key={`website.${website.url}`}
                  className="d-flex align-items-center "
                  href={website.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "none",
                    color: "black",
                    display: "flex",
                    // marginLeft: "10px",
                    margin: "0px 0px 5px 30px",
                  }}
                >
                  {getWebsiteIcon(website.category_name)}
                  <span
                    key={`website.cat.${website.category_name}`}
                    style={{ marginLeft: "20px" }}
                  >
                    {website.category_name}
                  </span>{" "}
                </a>
              ))}
          </Row>
        </Col>

        {/* Second Col */}
        <Col>
          <Row className="game_header">
            <Col xs={2}>
              <div className="score_title">AVERAGE SCORE</div>
              <div
                className={`score_display ${avgRating > 7
                  ? "high-rating"
                  : avgRating > 4
                    ? "medium-rating"
                    : "low-rating"
                  }`}
              >
                {avgRating ? avgRating : "N/A"}
              </div>
            </Col>
            <Col xs={1} className="vr" style={{ width: "1px" }}></Col>

            <Col xs={9}>
              <h1 className="text-5xl font-bold">{game.title}</h1>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "15px",
                  }}
                >
                  <FaCheckCircle size={32} />
                  <span style={{ marginLeft: "8px" }}>
                    {game.completed_count} Played
                  </span>
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "15px",
                  }}
                >
                  <FaGamepad size={32} />
                  <span style={{ marginLeft: "8px" }}>
                    {game.playing_count} Playing
                  </span>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <FaClock size={32} />
                  <span style={{ marginLeft: "8px" }}>
                    {" "}
                    {game.want_count} Want to play
                  </span>
                </div>
              </div>
            </Col>
          </Row>
          {isAuthenticated && user && (
            <Row className="user_edit_display ">
              <Form.Group
                as={Col}
                className="mb-1 mt-1"
                controlId="game_status"
              >
                <Form.Select
                  name="game_status"
                  value={userStatus}
                  onChange={onChangeStatus}
                >
                  <option value="">Not Played</option>
                  {STATUS_CHOICES_DISPLAY.map((choice, i) => (
                    <option
                      key={"game_status." + choice}
                      value={STATUS_CHOICES[i]}
                    >
                      {choice}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group as={Col} className="mb-1 mt-1" controlId="rating">
                <Form.Select
                  name="rating"
                  value={userScore}
                  onChange={onChangeScore}
                >
                  <option value="">Select Rating</option>
                  {Array.from({ length: 10 }, (_, index) => 10 - index).map(
                    (i) => (
                      <option key={"rating." + i} value={i}>
                        {i}
                      </option>
                    )
                  )}
                </Form.Select>
              </Form.Group>
              <Col
                xs="auto"
                className="d-flex justify-content-center align-items-center"
                style={{ margin: "auto" }}
              >
                <FavouriteButton
                  gameTitle={game.title}
                  user={user.user.id}
                  gameId={game.id}
                  initialFavourite={userFavourite}
                  access={access}
                  checkAuthenticated={checkAuthenticated}
                />
              </Col>
            </Row>
          )}
          <Row className="mb-2 mt-2">
            <Col>
              <h6>
                <strong>Summary</strong>
              </h6>
              <div className="text-light-gray">{game.summary}</div> <hr />
            </Col>
          </Row>
          {game.release_date && (
            <Row>
              <Col xs={2}>
                <strong>Release Date:</strong>
              </Col>
              <Col xs={6}>
                {" "}
                <div className="text-light-gray">{game.release_date}</div>
              </Col>
            </Row>
          )}

          {game.platforms && game.platforms.length > 0 && (
            <Row style={{ marginTop: "10px" }}>
              <Col xs={2}>
                {" "}
                <strong>Platforms:</strong>
              </Col>
              <Col xs={6}>
                <div className="text-light-gray">
                  {game.platforms.map((platform) => platform.name).join(", ")}
                </div>
              </Col>
            </Row>
          )}

          {game.genres && game.genres.length > 0 && (
            <Row style={{ marginTop: "10px" }}>
              <Col xs={2}>
                <strong>Genres:</strong>
              </Col>
              <Col xs={6}>
                {game.genres.map((genre, index) => (
                  <TagButton
                    key={`${game.id}-${index}`}
                    label={genre.name}
                    color="#737076"
                    textColor="#ececec"
                    hoverColor="#9758d7"
                    hoverTextColor="#ffffff"
                    fontWeight="bold"
                    margin="3px 0px 0px 3px"
                    onClick={() => genreOnClick(genre.name)}
                  />
                ))}
              </Col>
            </Row>
          )}

          {"engine" in game && game.engine.length > 0 && (
            <Row style={{ marginTop: "10px" }}>
              <Col xs={2}>
                <strong>Engines:</strong>
              </Col>
              <Col xs={6}>
                <div className="text-light-gray">
                  {game.engine.map((engine) => (
                    <span key={engine.id}>{engine.name}</span>
                  ))}
                </div>
              </Col>
            </Row>
          )}

          {game.publishers && game.publishers.length > 0 && (
            <Row style={{ marginTop: "10px" }}>
              <Col xs={2}>
                <strong>Publishers:</strong>
              </Col>
              <Col xs={6}>
                <div className="text-light-gray">
                  {game.publishers
                    .map((publisher) => publisher.name)
                    .join(", ")}
                </div>
              </Col>
            </Row>
          )}

          {game.developers && game.developers.length > 0 && (
            <Row style={{ marginTop: "10px" }}>
              <Col xs={2}>
                <strong>Developers:</strong>
              </Col>
              <Col xs={6}>
                <div className="text-light-gray">
                  {game.developers
                    .map((developer) => developer.name)
                    .join(", ")}
                </div>
              </Col>
            </Row>
          )}

          <Modal
            show={showReviewModal}
            onHide={handleReviewModalClose}
            size="lg"
          >
            <Modal.Header closeButton>
              <Modal.Title>Review for {game?.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {isAuthenticated ? (
                <ReviewForm
                  gameId={game?.id}
                  userId={user?.user?.id}
                  existingReview={existingReview}
                  onSubmit={handleReviewSubmit} // Pass the callback to ReviewForm
                />
              ) : (
                <p>Please log in to write a review.</p>
              )}
            </Modal.Body>
          </Modal>

          <Row>
            <Col>
              <div className="reviews-tag">
                <strong>Reviews</strong>
              </div>
              {isAuthenticated ? (
                <Button
                  variant="primary"
                  className="reviewButton"
                  onClick={handleReviewModalShow}
                >
                  Write a Review
                </Button>
              ) : null}
              {reviewsToShow.map((review) => (
                <Review
                  key={review.id}
                  imageUrl={review.user_profile_picture}
                  username={review.username}
                  reviewText={review.text}
                  score={review.user_score}
                />
              ))}
              {allExistingReviews.length > 3 && (
                <button
                  onClick={toggleShowAllReviews}
                  style={{
                    backgroundColor: "#737076" /* Green */,
                    border: "none",
                    color: "white",
                    padding: "5px 5px",
                    textAlign: "center",
                    textDecoration: "none",
                    display: "inline-block",
                    fontSize: "16px",
                    margin: "8px 2px",
                    cursor: "pointer",
                    borderRadius: "4px",
                    transitionDuration: "0.4s",
                  }}
                  onMouseOver={(e) => {
                    e.target.style.backgroundColor = "#9758d7";
                  }}
                  onMouseOut={(e) => {
                    e.target.style.backgroundColor = "#737076";
                  }}
                >
                  {showAllReviews ? "View less \u25B2" : "View more \u25BC"}
                </button>
              )}
            </Col>
          </Row>
          {/* 
          <Row className="mb-2 mt-2">
            <Col
              style={{
                backgroundColor: "white",
                padding: "10px",
                borderRadius: " 25px",
                boxShadow:
                  "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
                marginRight: "40px",
                marginBottom: "30px",
              }}
            >
              <h6>
                <strong>Recommendations</strong>
              </h6>
            </Col>
          </Row> */}
        </Col>
      </Row>
      {showNotification && (
        <div className="notification">{notificationMessage}</div>
      )}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  access: state.auth.access,
});

export default connect(mapStateToProps, { checkAuthenticated })(GameDetails);
