import { useRef, useMemo, useEffect } from "react";
import { debounce } from 'lodash';

export const useDebounce = (callback) => {
    const ref = useRef();
  
    useEffect(() => {
      ref.current = callback;
    }, [callback]);
  
    const debouncedCallback = useMemo(() => {
      const func = () => {
        ref.current?.();
      };
  
      return debounce(func, 500);
    }, []);
  
    return debouncedCallback;
  };