import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import axios from "axios";
import { combineMessages } from "../util/util";
import { useNavigate } from "react-router-dom";
import "./EditUserForm.css";

const EditUserForm = ({ user, access, load_user, checkAuthenticated, resetBiography }) => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [showNotification, setShowNotification] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [formData, setFormData] = useState({
    username: user.username,
    email: user.email,
    biography: user.biography,
  });

  const { username, email, biography } = formData;

  const onChange = (e) => {
    if (e.target.name === "biography" && e.target.value.length > 1000) {
      setFormData({
        ...formData,
        ["biography"]: e.target.value.slice(0, 1000),
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setErrorMessage("");
    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `JWT ${access}`,
      },
    };
    const body = JSON.stringify(formData);
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/api/users/${user.id}/update_useraccount/`,
        body,
        config
      )
      .then((res) => {
        setFormData({
          username: res.data["username"],
          email: res.data["email"],
          biography: res.data["biography"],
        });
        navigate(`/profile/${res.data["username"]}`);
        load_user();
        resetBiography(res.data["biography"]);
        setNotificationMessage("Successfully updated!");
        setShowNotification(true);
        setTimeout(() => {
          setShowNotification(false);
        }, 3000);
      })
      .catch((err) => {
        const res = err.response;
        var message = "Something went wrong with your edit.";
        if (Object.keys(res.data).length !== 0) {
          message = combineMessages(res);
          if (res.data.hasOwnProperty("code") && res.data.code === 'token_not_valid') {
            checkAuthenticated();
            message = "Your login has expired. Please try again.";
          }
        }
        setErrorMessage(message);
      });
  };

  return (
    <>
      {errorMessage && errorMessage !== "" && (
        <p style={{ color: "red" }}>{errorMessage}</p>
      )}
      <Form
        onSubmit={onSubmit}
        className="p-3"
        style={{ maxWidth: "60%", margin: "0 auto" }}
      >
        <Form.Group controlId="formBasicUsername">
          <Form.Label style={{ display: "block" }}>Username</Form.Label>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <Form.Control
              type="text"
              placeholder="Username"
              name="username"
              value={username}
              onChange={onChange}
              required
              style={{ marginBottom: "2rem", width: "40%" }}
            />
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicEmail">
          <Form.Label style={{ display: "block" }}>Email</Form.Label>
          <div style={{ display: "flex", justifyContent: "left" }}>
            <Form.Control
              type="email"
              placeholder="Email"
              name="email"
              value={email}
              onChange={onChange}
              required
              style={{ marginBottom: "2rem", width: "40%" }}
            />
          </div>
        </Form.Group>

        <Form.Group controlId="formBasicBiography">
          <Form.Label style={{ display: "block", textAlign: "left" }}>
            Biography
          </Form.Label>
          <Form.Control
            as="textarea"
            placeholder="Enter your biography here..."
            name="biography"
            value={biography}
            onChange={onChange}
            rows={5}
            cols={10}
            style={{ marginBottom: "2rem" }}
          />
        </Form.Group>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button variant="primary" type="submit" className="login-button">
            Submit
          </Button>
        </div>
      </Form>
      {showNotification && (
        <div className="notification">{notificationMessage}</div>
      )}
    </>
  );
};

export default EditUserForm;
